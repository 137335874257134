import React, { FC, useEffect, useMemo, useState } from 'react';
import { Modal, ModalHeader } from 'components/modal';
import { StockMachineOption, StockMachineOptionFields } from 'types/vendor';
import { useTranslation } from 'react-i18next';
import { get } from 'utils';
import { ScModalContent } from './StockOptionsModal.styles';
import { useMachineApi } from 'context/machine/useMachineApi';
import Table from 'components/table';
import useColumns from './use-columns';
import { sortTableDataBySearchValue } from 'utils/format';
import { Input } from 'components/form/Input';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

interface Props {
  machineCode: string;
  onClose: () => void;
}
export type StockOptionsModalState = Props;
const StockOptionsModal: FC<Props> = ({ onClose, machineCode }) => {
  const { t } = useTranslation();
  const { getStockMachineOptionsList } = useMachineApi();

  const [data, setData] = useState<StockMachineOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const preparedData = useMemo(() => {
    return sortTableDataBySearchValue({ data: data, searchValue: searchValue });
  }, [searchValue, data]);

  useEffect(() => {
    let on = true;

    setIsLoading(true);
    const fn = () => {
      getStockMachineOptionsList(machineCode)
        .then(({ response }) => {
          if (on) {
            setData(response ?? []);
          }
        })
        .finally(() => {
          if (on) {
            setIsLoading(false);
          }
        });
    };

    const timeoutHandle = setTimeout(fn, 500);
    return () => {
      clearTimeout(timeoutHandle);
      on = false;
    };
  }, [getStockMachineOptionsList, machineCode]);

  const columns = useColumns();

  return (
    <Modal variant="md" visible={true} onCancel={onClose} center>
      <ModalHeader $textAlign="center">
        {t('PRICE_LIST_STOCK_OPTIONS_MODAL__TITLE')}
      </ModalHeader>

      <ScModalContent>
        <Input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          suffix={<SearchIcon />}
        />
        <Table
          loading={isLoading}
          rowKey={record => get(record, StockMachineOptionFields.ID)}
          columns={columns}
          data={preparedData}
          pageSize={10}
          scroll={{ x: 800 }}
          pagination
          showSizeChanger={false}
        />
      </ScModalContent>
    </Modal>
  );
};

export default StockOptionsModal;

import styled from 'styled-components';

export const ScNavLink = styled.div`
  font-size: 0.75em;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ScIconWrap = styled.span`
  font-size: 1.5em;
  margin-right: 1em;
`;

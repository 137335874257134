import { DocumentsTreeData } from 'hooks/useDocumentsTree/types';
import { DocumentRelatedEntityCode } from 'context/document/types';

export const prepareDocumentsForEntity = (
  documentsTree: DocumentsTreeData,
  entityCode: DocumentRelatedEntityCode,
): { id: number; url: string; name: string; selected: boolean }[] => {
  return documentsTree
    .filter(item => item.relatedEntityCode === entityCode)
    .map(item => item.relatedEntities)
    .flat()
    .map(({ selectedIds, documents }) => {
      return documents.map(doc => {
        return {
          id: Number(doc.id),
          url: doc.fileUrl,
          selected: selectedIds.includes(doc.id),
          name: doc.name,
        };
      });
    })
    .flat();
};

import React, { FC, useCallback, useMemo, useState } from 'react';
import { DocumentQuoteModal, DocumentQuoteModalProps } from '../DocumentQuoteModal';
import {
  ScCheckIcon,
  ScCloseIcon,
  ScDocumentsFooterWrapper,
  ScDownloadPdfButton,
  ScListingItem,
  ScListingItemIconWrapper,
  ScListingItemTextWrapper,
  ScPrevStepButton,
  ScWebhookButton,
  ScSendQuoteButton,
} from './DocumentsFooter.styles';
import { notification } from 'utils/notification';
import { ReactComponent as PDF } from 'assets/icons/pdf.svg';
import { MailtoUrls } from '../utils';
import { copyToClipboardAsHtml } from 'utils/copyToClipboard';
import { Status } from 'utils/types';
import { DocumentsTreeData } from 'hooks/useDocumentsTree';
import { useTranslation } from 'react-i18next';
import { DocumentTemplateDropdown } from 'components/documentTemplate/DocumentTemplateDropdown';
import { Tooltip } from 'antd';
import { Button } from 'components/button';
import { PrintTemplateListItemFields } from '@hypercharge/xdms-client/lib/types';
import { PRIMARY_DYNAMIC_FIELD } from 'common/constants';
import { DocumentTemplate } from 'types/vendor';

interface Props {
  documentsSchema: DocumentsTreeData;
  handleGoToPrevStep(): void;
  sendQuoteEmailEnabled: boolean;
  sendQuoteEmailVisible: boolean;
  sendQuoteCustomEmailUrls?: MailtoUrls;
  handleDownloadPdfDocuments(): void;
  downloadPdfDocumentsEnabled: boolean;
  downloadPdfDocumentsVisible: boolean;
  isAllowDocumentsPagePublishButtonFeatureEnabled: boolean;
  pdfTemplates: DocumentTemplate[];
  publishConfiguration(template?: DocumentTemplate): Promise<void>;
  isConfigurationComplete: boolean;
  requiredItems: [string, boolean][];
  modelPublishAllowed: boolean;
  isWebhookEnabled: boolean;
  onWebhook(): void;
}

const DocumentsFooter: FC<Props> = ({
  documentsSchema,
  handleGoToPrevStep,
  sendQuoteEmailEnabled,
  sendQuoteEmailVisible,
  sendQuoteCustomEmailUrls,
  handleDownloadPdfDocuments,
  downloadPdfDocumentsEnabled,
  downloadPdfDocumentsVisible,
  isAllowDocumentsPagePublishButtonFeatureEnabled,
  pdfTemplates,
  publishConfiguration,
  isConfigurationComplete,
  requiredItems,
  modelPublishAllowed,
  isWebhookEnabled,
  onWebhook,
}) => {
  const { t } = useTranslation();

  const [sendEmailState, setSendEmailState] = useState<DocumentQuoteModalProps | null>(
    null,
  );

  const handleSetSendEmailState = useCallback(() => {
    setSendEmailState({
      documentsSchema: documentsSchema,
      onCancel: () => setSendEmailState(null),
    });
  }, [setSendEmailState, documentsSchema]);

  const handleSendQuote = useCallback(() => {
    if (!sendQuoteCustomEmailUrls) return;

    const status = copyToClipboardAsHtml(sendQuoteCustomEmailUrls.body);

    notification.openByStatus(status, {
      [Status.Success]: t('DOCUMENTS_CUSTOM_EMAIL_BUTTON__HTML_COPIED__SUCCESS'),
      [Status.Error]: t('DOCUMENTS_CUSTOM_EMAIL_BUTTON__HTML_COPIED__ERROR'),
    });
  }, [sendQuoteCustomEmailUrls, t]);

  const [isLoadingPublish, setIsLoadingPublish] = useState(false);

  const isRequiredItemMissing = requiredItems.some(([, isConfigured]) => !isConfigured);

  const primaryPdfTemplates = useMemo(() => {
    return pdfTemplates.filter(
      pdfTemplate =>
        pdfTemplate[PrintTemplateListItemFields.dynamicField] === PRIMARY_DYNAMIC_FIELD,
    );
  }, [pdfTemplates]);

  const tooltipTitle = useMemo(() => {
    if (isConfigurationComplete) return t('HEADER_PUBLISHED_CONFIGURATION_LOCK');

    return isRequiredItemMissing ? (
      <ul>
        {requiredItems.map(([displayName, isConditionMet]) => (
          <ScListingItem key={displayName}>
            <ScListingItemIconWrapper>
              {isConditionMet ? <ScCheckIcon /> : <ScCloseIcon />}
            </ScListingItemIconWrapper>
            <ScListingItemTextWrapper>{displayName}</ScListingItemTextWrapper>
          </ScListingItem>
        ))}
      </ul>
    ) : (
      ''
    );
  }, [isConfigurationComplete, isRequiredItemMissing, requiredItems, t]);

  const handlePublish = useCallback(
    async (template: DocumentTemplate) => {
      setIsLoadingPublish(true);
      await publishConfiguration(template);
      setIsLoadingPublish(false);
    },
    [publishConfiguration],
  );

  return (
    <ScDocumentsFooterWrapper>
      <ScPrevStepButton variant="prev" onClick={handleGoToPrevStep}>
        {t('DOCUMENT_BACK')}
      </ScPrevStepButton>
      {sendEmailState && <DocumentQuoteModal {...sendEmailState} />}
      {downloadPdfDocumentsVisible && (
        <ScDownloadPdfButton
          onClick={handleDownloadPdfDocuments}
          disabled={!downloadPdfDocumentsEnabled}
          icon={<PDF />}
        >
          {t('DOCUMENT_GET_PDF')}
        </ScDownloadPdfButton>
      )}
      {sendQuoteEmailVisible && (
        <ScSendQuoteButton
          variant="highlighted"
          onClick={handleSetSendEmailState}
          disabled={!sendQuoteEmailEnabled}
        >
          {t('DOCUMENT_SEND_QUOTE')}
        </ScSendQuoteButton>
      )}
      {isAllowDocumentsPagePublishButtonFeatureEnabled && (
        <DocumentTemplateDropdown
          templates={primaryPdfTemplates}
          onAction={handlePublish}
          stubTitle={t('TOTAL_NOT_FOUND_TEMPLATES')}
          placement="topCenter"
          trigger={['click']}
          disabled={isConfigurationComplete || !modelPublishAllowed}
          withConfirm={true}
          confirmPlacement="right"
          confirmOffsetX={17}
        >
          <Tooltip placement="top" title={tooltipTitle}>
            {/* need wrapper div otherwise tooltip does not interact with disabled button */}
            <div>
              <Button
                fullwidth
                variant="highlighted"
                data-testid="total-page-publish-quote-btn"
                disabled={
                  isConfigurationComplete || isRequiredItemMissing || isLoadingPublish
                }
                loading={isLoadingPublish}
              >
                {t('TOTAL_PUBLISH_QUOTE')}
              </Button>
            </div>
          </Tooltip>
        </DocumentTemplateDropdown>
      )}
      {sendQuoteCustomEmailUrls && (
        <ScSendQuoteButton
          variant="highlighted"
          href={sendQuoteCustomEmailUrls.urlNoBody}
          onClick={handleSendQuote}
        >
          {t('DOCUMENTS_CUSTOM_EMAIL_BUTTON')}
        </ScSendQuoteButton>
      )}
      {isWebhookEnabled && (
        <ScWebhookButton variant="highlighted" onClick={onWebhook}>
          {t('DOCUMENTS_WEBHOOK_BUTTON')}
        </ScWebhookButton>
      )}
    </ScDocumentsFooterWrapper>
  );
};

export default DocumentsFooter;

import {
  BannerSettings,
  SectionsDynamicTypes,
} from '../pages/total/sectionsDynamic/types';
import { Sign } from '../pages/total/sectionsDynamic/_common/filter';
import { ComponentsRendererSchema } from 'hooks/useComponentsRenderer/types';
import { ContainerSize } from './theme';
import { FeatureFlagColumnSettings, FeatureFlagFieldSettings } from 'types/common';

export enum GlobalFeaturesFlagsFields {
  GOXEnabled = 'YNGOXEnabled',
  RequiredCategories = 'YNRequiredCategories',
  XDMSAppInst = 'XDMSAppInst',

  allowEditPackageLinesDetails = 'YNallowEditPackageLinesDetails',

  // + allow docs for static pages
  allowDocumentsConfigurations = 'YNallowDocumentsConfigurations',
  allowDocumentsTradeIns = 'YNallowDocumentsTradeIns',
  allowDocumentsOptions = 'YNallowDocumentsOptions',
  allowDocumentsTermsAndConditions = 'YNallowDocumentsTermsAndConditions',
  allowDocumentsAccessories = 'YNallowDocumentsAccessories',
  allowDocumentsConfigurationsPublish = 'YNallowDocumentConfigurationPublish',
  // - allow docs for static pages

  // + relations
  Relation1Enabled = 'YNRelation1Enabled',
  Relation2Enabled = 'YNRelation2Enabled',
  Relation3Enabled = 'YNRelation3Enabled',

  Relation1Mandatory = 'YNRelation1Mandatory',
  Relation2Mandatory = 'YNRelation2Mandatory',
  Relation3Mandatory = 'YNRelation3Mandatory',

  Relation1CustomerAddEnabled = 'YNRelation1CustomerAddEnabled',
  Relation2CustomerAddEnabled = 'YNRelation2CustomerAddEnabled',
  Relation3CustomerAddEnabled = 'YNRelation3CustomerAddEnabled',

  Relation1ContactAddEnabled = 'YNRelation1ContactAddEnabled',
  Relation2ContactAddEnabled = 'YNRelation2ContactAddEnabled',
  Relation3ContactAddEnabled = 'YNRelation3ContactAddEnabled',

  Relation1CustomerAddressFieldRequired = 'YNRelation1CustomerAddressFieldRequired',
  Relation2CustomerAddressFieldRequired = 'YNRelation2CustomerAddressFieldRequired',
  Relation3CustomerAddressFieldRequired = 'YNRelation3CustomerAddressFieldRequired',

  Relation1CustomerEmailFieldRequired = 'YNRelation1CustomerEmailFieldRequired',
  Relation2CustomerEmailFieldRequired = 'YNRelation2CustomerEmailFieldRequired',
  Relation3CustomerEmailFieldRequired = 'YNRelation3CustomerEmailFieldRequired',

  Relation1CustomerPhoneFieldRequired = 'YNRelation1CustomerPhoneFieldRequired',
  Relation2CustomerPhoneFieldRequired = 'YNRelation2CustomerPhoneFieldRequired',
  Relation3CustomerPhoneFieldRequired = 'YNRelation3CustomerPhoneFieldRequired',

  Relation1CustomerPhone2FieldRequired = 'YNRelation1CustomerPhone2FieldRequired',
  Relation2CustomerPhone2FieldRequired = 'YNRelation2CustomerPhone2FieldRequired',
  Relation3CustomerPhone2FieldRequired = 'YNRelation3CustomerPhone2FieldRequired',

  Relation1CustomerMobilePhoneFieldRequired = 'YNRelation1CustomerMobilePhoneFieldRequired',
  Relation2CustomerMobilePhoneFieldRequired = 'YNRelation2CustomerMobilePhoneFieldRequired',
  Relation3CustomerMobilePhoneFieldRequired = 'YNRelation3CustomerMobilePhoneFieldRequired',

  Relation1CustomerMobilePhone2FieldRequired = 'YNRelation1CustomerMobilePhone2FieldRequired',
  Relation2CustomerMobilePhone2FieldRequired = 'YNRelation2CustomerMobilePhone2FieldRequired',
  Relation3CustomerMobilePhone2FieldRequired = 'YNRelation3CustomerMobilePhone2FieldRequired',

  RelationSwapEnabled = 'YNRelationSwapEnabled',
  RelationCopyEnabled = 'YNRelationCopyEnabled',
  ContactsEnabled = 'YNContactsEnabled',
  CustomerTypeEnabled = 'YNCustomerTypeEnabled',
  CustomerCreateTypeEnabled = 'YNCustomerCreateTypeEnabled',
  SkipNoMail = 'YNSkipNoMail',
  CommercialTypeEnabled = 'YNCommercialTypeEnabled',
  CustomerTitleEnabled = 'YNCustomerTitleEnabled',
  CustomerSalutationEnabled = 'YNCustomerSalutationEnabled',
  CommercialTypeEditable = 'YNCommercialTypeEditable', // controls if field is disabled
  CommercialTypeRequired = 'YNCommercialTypeRequired', // depends on YNCommercialTypeEnabled
  allowConfigurationUpdateOnClientUpdate = 'YNallowConfigurationUpdateOnClientUpdate',
  allowContactMandatory = 'YNallowContactMandatory',
  CustomerVatEditEnabled = 'YNCustomerVatEditEnabled',
  SearchCustomerColumns = 'SearchCustomerColumns',
  allowUpdateDossierCustomer = 'YNallowUpdateDossierCustomer',
  // - relations

  // + for PriceList page
  allowCreateFromStock = 'YNallowCreateFromStock',
  allowStockMachineOptions = 'YNallowStockMachineOptions',
  StockMachineFields = 'StockMachineFields',
  StockMachineDefaultIsInStock = 'StockMachineDefaultIsInStock',
  StockMachineDefaultCustomerId = 'StockMachineDefaultCustomerId',
  StockMachineDefaultBrand = 'StockMachineDefaultBrand',
  StockMachineColumns = 'StockMachineColumns',
  // allowShowBackgroundImage = 'YNallowShowBackgroundImage',
  // - for PriceList page

  // + for Model page
  allowShowBackgroundImage = 'YNallowShowBackgroundImage',
  AutoCompleteEnabled = 'YNAutoCompleteEnabled',
  allowModelExtendedView = 'YNallowModelExtendedView',
  allowModelExtendedViewByDefault = 'YNallowModelExtendedViewByDefault',
  allowInheritBackgroundImageModel = 'YNallowInheritBackgroundImageModel',
  allowInheritBackgroundImageAllSubsteps = 'YNallowInheritBackgroundImageAllSubsteps',
  allowInheritBackgroundImageFirstSubStep = 'YNallowInheritBackgroundImageFirstSubStep',
  // - for Model page

  // + ConfigurationsList page
  allowConfigurationListView = 'YNallowConfigurationListView',
  allowConfigurationListCopy = 'YNallowConfigurationListCopy',
  allowConfigurationListChangeStatus = 'YNallowConfigurationListChangeStatus',
  allowConfigurationListNewVersion = 'YNallowConfigurationListNewVersion',
  allowConfigurationListCreateConfig = 'YNallowConfigurationListCreateConfig',
  allowConfigurationListImport = 'YNallowConfigurationListImport',
  ConfigurationListColumns = 'ConfigurationListColumns',
  // - ConfigurationsList page

  // + Terms and Conditions page
  allowTermsAndConditionsStep = 'YNallowTermsAndConditionsStep',
  // - Terms and Conditions page

  // + Configuration Import
  allowConfigurationImport = 'YNallowConfigurationImport',
  allowConfigurationImportCreate = 'YNallowConfigurationImportCreate',
  allowConfigurationImportUpdate = 'YNallowConfigurationImportUpdate',
  allowConfigurationImportUpdateCurrent = 'YNallowConfigurationImportUpdateCurrent',
  ConfigurationImportSource = 'ConfigurationImportSource',
  // - Configuration Import

  // + for info page
  allowCustomerComment = 'YNallowCustomerComment',
  allowCustomerReference = 'YNallowCustomerReference',
  allowInternalComment = 'YNallowInternalComment',
  allowDeliveryDate = 'YNallowDeliveryDate',
  allowDeliveryContact = 'YNallowDeliveryContact',
  allowFinancialContact = 'YNallowFinancialContact',
  allowConditions = 'YNallowConditions',
  allowConditionsDelivery = 'YNallowConditionsDelivery',
  allowInfoPageSalesCode = 'YNallowInfoPageSalesCode',
  allowInfoPageSellCode = 'YNallowInfoPageSellCode',
  allowInfoPageSaleGroupCode = 'YNallowInfoPageSaleGroupCode',
  allowInfoPageConfigurationCustomerCommercialType = 'YNallowInfoPageConfigurationCustomerCommercialType',
  shouldPrefillSalesCode = 'YNSalesCodePrefill',

  InfoFormSchema = 'InfoFormSchema',
  InfoContainerWidth = 'InfoContainerWidth',
  // - for info page

  // + for taxes page
  allowTaxesPage = 'YNallowTaxesPage',
  // - for taxes page

  // + softOffer page
  allowSoftOfferPage = 'YNallowSoftOfferPage',
  allowSoftOfferAddNew = 'YNallowSoftOfferAddNew',
  allowSoftOfferEdit = 'YNallowSoftOfferEdit',
  allowSoftOfferDelete = 'YNallowSoftOfferDelete',

  allowSoftOfferUsualDriverForm = 'YNallowSoftOfferUsualDriverForm',
  allowSoftOfferInsurancePolicyHolderForm = 'YNallowSoftOfferInsurancePolicyHolderForm',
  allowSoftOfferAcceptionQuestionsForm = 'YNallowSoftOfferAcceptionQuestionsForm',
  allowSoftOfferVehicleDetailsForm = 'YNallowSoftOfferVehicleDetailsForm',
  allowSoftOfferSecurityDepositForm = 'YNallowSoftOfferSecurityDepositForm',
  allowSoftOfferOccasionalDriversForm = 'YNallowSoftOfferOccasionalDriversForm',

  SoftOfferColumns = 'SoftOfferColumns',
  // - softOffer page

  // + financing page
  allowFinancingPage = 'YNallowFinancingPage',
  allowFinancingAddNew = 'YNallowFinancingAddNew',
  allowFinancingEdit = 'YNallowFinancingEdit',
  allowFinancingSelect = 'YNallowFinancingSelect',
  allowFinancingDelete = 'YNallowFinancingDelete',

  allowFinancingCarInfoForm = 'YNallowFinancingCarInfoForm',
  allowFinancingCustomerForm = 'YNallowFinancingCustomerForm',
  allowFinancingListItemForm = 'YNallowFinancingListItemForm',

  FinancingColumns = 'FinancingColumns',
  // - financing page

  // + for images
  ImageOptimizerEndpoint = 'ImageOptimizerEndpoint',
  // - for images

  // + for tradeIn page
  allowTradeInAddNew = 'YNallowTradeInAddNew',
  allowTradeInDelete = 'YNallowTradeInDelete',
  allowTradeInUpdate = 'YNallowTradeInUpdate',
  allowTradeInView = 'YNallowTradeInView',
  allowTradeInSendEmail = 'YNallowTradeInSendEmail',
  allowTradeInAddFile = 'YNallowTradeInAddFile',
  allowTradeInAddLink = 'YNallowTradeInAddLink',
  allowTradeInMargin = 'YNallowTradeInMargin',
  allowTradeInStandingValueField = 'YNallowTradeInStandingValueField',
  allowTradeInVarianceField = 'YNallowTradeInVarianceField',
  allowTradeInDescriptionFieldOptional = 'YNallowTradeInDescriptionFieldOptional',
  allowTradeInMachineNumberFieldOptional = 'YNallowTradeInMachineNumberFieldOptional',

  TradeInMachineSearchModalColumns = 'TradeInMachineSearchModalColumns',
  TradeInColumns = 'TradeInColumns',
  // - for tradeIn page

  // + for Total page
  allowTotalShowHOD = 'YNallowTotalShowHOD', //: "false" | "true"',
  TotalPageBannersSettings = 'TotalPageBannersSettings',
  allowTotalShowZeroDiscount = 'YNallowTotalShowZeroDiscount', //: "false" | "true" - ( true - if discount is 0, show 0 same as now | false = show empty space)',
  allowTotalSplitOptions = 'YNallowTotalSplitOptions', //:  "true" | "false" - ( display Default Options and Selected Options under different spoilers)',
  allowTotalShowSubtotalExManufacturer = 'YNallowTotalShowSubtotalExManufacturer', //: "false" | "true"',
  allowTotalShowOptional = 'YNallowTotalShowOptional', //: "false" | "true" - display spoiler with Optional Options of given step',
  allowTotalShowDeselected = 'YNallowTotalShowDeselected', //: "false" | "true" - display spoiler with Deselected Options of given step',
  allowTotalShowSalesInfo = 'YNallowTotalShowSalesInfo', //: "false" | "true" - display Sales Info section',
  allowHideEmptyCollapsibleRows = 'YNallowTotalHideEmptyCollapsibleRows', //: "false" | "true" - dont/do render collapses if no items to render',
  allowTotalDocumentPrint = 'YNallowTotalDocumentPrint',
  allowTotalLimitBalanceValue = 'YNallowTotalLimitBalanceValue',
  allowTotalBaseModelPriceEdit = 'YNallowTotalBaseModelPriceEdit',
  allowTotalGeneratePublishImage = 'YNallowTotalGeneratePublishImage',
  allowTotalTradeInsSeparateView = 'YNallowTotalTradeInsSeparateView',
  // - for Total page

  // + for Documents page
  allowDocumentsPageUploadLink = 'YNdocumentsPageUploadLink',
  allowDocumentsPageUploadFile = 'YNdocumentsPageUploadFile',
  allowDocumentsPageGetPDFButton = 'YNallowDocumentsPageGetPDFButton',
  allowDocumentsPageEmailButton = 'YNallowDocumentsPageEmailButton',
  allowDocumentsPageCustomEmailButton = 'YNallowDocumentsPageCustomEmailButton',
  allowDocumentsPagePublishButton = 'YNallowDocumentsPagePublishButton',
  shouldSortByNewestDocuments = 'YNshouldSortByNewestDocuments',
  // - for Documents page

  // + for Header
  allowHeaderGalleryEnabled = 'YNallowHeaderGalleryEnabled',
  allowShowModelNumberHeader = 'YNShowModelNumberHeader',
  HeaderTotalField = 'HeaderTotalField',
  HeaderTotalField_withVAT = 'HeaderTotalFieldWithVAT',
  allowHeaderNewVersionButton = 'YNallowHeaderNewVersionButton',
  allowHeaderCopyButton = 'YNallowHeaderCopyButton',
  allowHeaderNewConfigurationButton = 'YNallowHeaderNewConfigurationButton',
  allowHeaderImportButton = 'YNallowHeaderImportButton',
  allowShowHeaderProspectDossierID = 'YNallowProspectIDHeaderDisplay',
  allowHeaderCurrency = 'YNallowHeaderCurrency',
  allowHeaderMargin = 'YNallowHeaderMargin',
  customLinkURL = 'CustomLinkURL',
  allowLoopend = 'YNallowLoopend',
  // - for Header

  // + for Streaming
  allowStreaming = 'YNAllowStreaming',
  allowForceStreaming = 'YNAllowForceStreaming',
  StreamingOptionFallbackImage = 'StreamingOptionFallbackImage',
  // - for Streaming
}

export type GlobalFeaturesFlags = {
  [GlobalFeaturesFlagsFields.GOXEnabled]: boolean;
  [GlobalFeaturesFlagsFields.RequiredCategories]: boolean;
  [GlobalFeaturesFlagsFields.XDMSAppInst]?: string;
  [GlobalFeaturesFlagsFields.allowEditPackageLinesDetails]: boolean;

  // + allow docs for static pages
  [GlobalFeaturesFlagsFields.allowDocumentsConfigurations]: boolean;
  [GlobalFeaturesFlagsFields.allowDocumentsTradeIns]: boolean;
  [GlobalFeaturesFlagsFields.allowDocumentsOptions]: boolean;
  [GlobalFeaturesFlagsFields.allowDocumentsTermsAndConditions]: boolean;
  [GlobalFeaturesFlagsFields.allowDocumentsAccessories]: boolean;
  [GlobalFeaturesFlagsFields.allowDocumentsConfigurationsPublish]: boolean;
  // - allow docs for static pages

  // + relations
  [GlobalFeaturesFlagsFields.Relation1Enabled]: boolean;
  [GlobalFeaturesFlagsFields.Relation2Enabled]: boolean;
  [GlobalFeaturesFlagsFields.Relation3Enabled]: boolean;

  [GlobalFeaturesFlagsFields.Relation1Mandatory]: boolean;
  [GlobalFeaturesFlagsFields.Relation2Mandatory]: boolean;
  [GlobalFeaturesFlagsFields.Relation3Mandatory]: boolean;

  [GlobalFeaturesFlagsFields.Relation1CustomerAddEnabled]: boolean;
  [GlobalFeaturesFlagsFields.Relation2CustomerAddEnabled]: boolean;
  [GlobalFeaturesFlagsFields.Relation3CustomerAddEnabled]: boolean;

  [GlobalFeaturesFlagsFields.Relation1ContactAddEnabled]: boolean;
  [GlobalFeaturesFlagsFields.Relation2ContactAddEnabled]: boolean;
  [GlobalFeaturesFlagsFields.Relation3ContactAddEnabled]: boolean;

  [GlobalFeaturesFlagsFields.Relation1CustomerAddressFieldRequired]: boolean;
  [GlobalFeaturesFlagsFields.Relation2CustomerAddressFieldRequired]: boolean;
  [GlobalFeaturesFlagsFields.Relation3CustomerAddressFieldRequired]: boolean;

  [GlobalFeaturesFlagsFields.Relation1CustomerPhoneFieldRequired]: boolean;
  [GlobalFeaturesFlagsFields.Relation2CustomerPhoneFieldRequired]: boolean;
  [GlobalFeaturesFlagsFields.Relation3CustomerPhoneFieldRequired]: boolean;

  [GlobalFeaturesFlagsFields.Relation1CustomerPhone2FieldRequired]: boolean;
  [GlobalFeaturesFlagsFields.Relation2CustomerPhone2FieldRequired]: boolean;
  [GlobalFeaturesFlagsFields.Relation3CustomerPhone2FieldRequired]: boolean;

  [GlobalFeaturesFlagsFields.Relation1CustomerMobilePhoneFieldRequired]: boolean;
  [GlobalFeaturesFlagsFields.Relation2CustomerMobilePhoneFieldRequired]: boolean;
  [GlobalFeaturesFlagsFields.Relation3CustomerMobilePhoneFieldRequired]: boolean;

  [GlobalFeaturesFlagsFields.Relation1CustomerMobilePhone2FieldRequired]: boolean;
  [GlobalFeaturesFlagsFields.Relation2CustomerMobilePhone2FieldRequired]: boolean;
  [GlobalFeaturesFlagsFields.Relation3CustomerMobilePhone2FieldRequired]: boolean;

  [GlobalFeaturesFlagsFields.ContactsEnabled]: boolean;
  [GlobalFeaturesFlagsFields.SkipNoMail]: boolean;
  [GlobalFeaturesFlagsFields.RelationSwapEnabled]: boolean;
  [GlobalFeaturesFlagsFields.RelationCopyEnabled]: boolean;
  [GlobalFeaturesFlagsFields.CustomerTypeEnabled]: boolean;
  [GlobalFeaturesFlagsFields.CustomerCreateTypeEnabled]: boolean;
  [GlobalFeaturesFlagsFields.CommercialTypeEnabled]: boolean;
  [GlobalFeaturesFlagsFields.CommercialTypeEditable]: boolean;
  [GlobalFeaturesFlagsFields.CustomerTitleEnabled]: boolean;
  [GlobalFeaturesFlagsFields.CustomerSalutationEnabled]: boolean;
  [GlobalFeaturesFlagsFields.CommercialTypeRequired]: boolean;
  [GlobalFeaturesFlagsFields.allowConfigurationUpdateOnClientUpdate]: boolean;
  [GlobalFeaturesFlagsFields.allowContactMandatory]: boolean;
  [GlobalFeaturesFlagsFields.SearchCustomerColumns]: FeatureFlagColumnSettings[];
  [GlobalFeaturesFlagsFields.allowUpdateDossierCustomer]: boolean;
  // - relations

  // + for PriceList page
  [GlobalFeaturesFlagsFields.allowCreateFromStock]: boolean;
  [GlobalFeaturesFlagsFields.allowStockMachineOptions]: boolean;
  [GlobalFeaturesFlagsFields.StockMachineFields]: Record<
    'national',
    FeatureFlagFieldSettings[]
  >;
  [GlobalFeaturesFlagsFields.StockMachineDefaultCustomerId]: string;
  [GlobalFeaturesFlagsFields.StockMachineDefaultIsInStock]: string;
  [GlobalFeaturesFlagsFields.StockMachineDefaultBrand]: string;
  [GlobalFeaturesFlagsFields.StockMachineColumns]: Record<
    'national',
    FeatureFlagColumnSettings[]
  >;
  // - for PriceList page

  // + for Model page
  [GlobalFeaturesFlagsFields.allowShowBackgroundImage]: boolean;
  [GlobalFeaturesFlagsFields.AutoCompleteEnabled]: boolean;
  [GlobalFeaturesFlagsFields.allowModelExtendedView]: boolean;
  [GlobalFeaturesFlagsFields.allowModelExtendedViewByDefault]: boolean;
  [GlobalFeaturesFlagsFields.allowInheritBackgroundImageModel]: boolean;
  [GlobalFeaturesFlagsFields.allowInheritBackgroundImageAllSubsteps]: boolean;
  [GlobalFeaturesFlagsFields.allowInheritBackgroundImageFirstSubStep]: boolean;
  // - for Model page

  // + ConfigurationsList page
  [GlobalFeaturesFlagsFields.allowConfigurationListView]: boolean;
  [GlobalFeaturesFlagsFields.allowConfigurationListCopy]: boolean;
  [GlobalFeaturesFlagsFields.allowConfigurationListChangeStatus]: boolean;
  [GlobalFeaturesFlagsFields.allowConfigurationListNewVersion]: boolean;

  [GlobalFeaturesFlagsFields.allowConfigurationListCreateConfig]: boolean;

  [GlobalFeaturesFlagsFields.ConfigurationListColumns]: FeatureFlagColumnSettings[];
  // - ConfigurationsList page

  // + Terms and Conditions page
  [GlobalFeaturesFlagsFields.allowTermsAndConditionsStep]: boolean;
  // - Terms and Conditions page

  // + Configuration Import
  [GlobalFeaturesFlagsFields.allowConfigurationImport]: boolean;
  [GlobalFeaturesFlagsFields.allowConfigurationImportCreate]: boolean;
  [GlobalFeaturesFlagsFields.allowConfigurationImportUpdate]: boolean;
  [GlobalFeaturesFlagsFields.allowConfigurationImportUpdateCurrent]: boolean;
  [GlobalFeaturesFlagsFields.ConfigurationImportSource]: string | null;
  // - Configuration Import

  // + for info page
  [GlobalFeaturesFlagsFields.allowCustomerComment]: boolean;
  [GlobalFeaturesFlagsFields.allowCustomerReference]: boolean;
  [GlobalFeaturesFlagsFields.allowInternalComment]: boolean;
  [GlobalFeaturesFlagsFields.allowDeliveryDate]: boolean;
  [GlobalFeaturesFlagsFields.allowDeliveryContact]: boolean;
  [GlobalFeaturesFlagsFields.allowFinancialContact]: boolean;
  [GlobalFeaturesFlagsFields.allowConditions]: boolean;
  [GlobalFeaturesFlagsFields.allowConditionsDelivery]: boolean;
  [GlobalFeaturesFlagsFields.allowInfoPageSalesCode]: boolean;
  [GlobalFeaturesFlagsFields.allowInfoPageSellCode]: boolean;
  [GlobalFeaturesFlagsFields.allowInfoPageSaleGroupCode]: boolean;
  [GlobalFeaturesFlagsFields.allowInfoPageConfigurationCustomerCommercialType]: boolean;

  [GlobalFeaturesFlagsFields.InfoFormSchema]: ComponentsRendererSchema;
  [GlobalFeaturesFlagsFields.InfoContainerWidth]: ContainerSize;
  // - for info page

  // + for taxes page
  [GlobalFeaturesFlagsFields.allowTaxesPage]: boolean;
  // - for taxes page

  // + softOffer page
  [GlobalFeaturesFlagsFields.allowSoftOfferPage]: boolean;
  [GlobalFeaturesFlagsFields.allowSoftOfferAddNew]: boolean;
  [GlobalFeaturesFlagsFields.allowSoftOfferEdit]: boolean;
  [GlobalFeaturesFlagsFields.allowSoftOfferDelete]: boolean;

  [GlobalFeaturesFlagsFields.allowSoftOfferUsualDriverForm]: boolean;
  [GlobalFeaturesFlagsFields.allowSoftOfferInsurancePolicyHolderForm]: boolean;
  [GlobalFeaturesFlagsFields.allowSoftOfferAcceptionQuestionsForm]: boolean;
  [GlobalFeaturesFlagsFields.allowSoftOfferVehicleDetailsForm]: boolean;
  [GlobalFeaturesFlagsFields.allowSoftOfferSecurityDepositForm]: boolean;
  [GlobalFeaturesFlagsFields.allowSoftOfferOccasionalDriversForm]: boolean;

  [GlobalFeaturesFlagsFields.SoftOfferColumns]: FeatureFlagColumnSettings[];
  // - softOffer page

  // + financing page
  [GlobalFeaturesFlagsFields.allowFinancingPage]: boolean;
  [GlobalFeaturesFlagsFields.allowFinancingAddNew]: boolean;
  [GlobalFeaturesFlagsFields.allowFinancingEdit]: boolean;
  [GlobalFeaturesFlagsFields.allowFinancingSelect]: boolean;
  [GlobalFeaturesFlagsFields.allowFinancingDelete]: boolean;

  [GlobalFeaturesFlagsFields.allowFinancingCarInfoForm]: boolean;
  [GlobalFeaturesFlagsFields.allowFinancingCustomerForm]: boolean;
  [GlobalFeaturesFlagsFields.allowFinancingListItemForm]: boolean;

  [GlobalFeaturesFlagsFields.FinancingColumns]: FeatureFlagColumnSettings[];
  // - financing page

  // + for images
  [GlobalFeaturesFlagsFields.ImageOptimizerEndpoint]: string | null;
  // - for images

  // + for tradeIn page
  [GlobalFeaturesFlagsFields.allowTradeInAddNew]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInDelete]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInUpdate]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInView]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInSendEmail]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInAddFile]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInAddLink]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInMargin]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInStandingValueField]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInVarianceField]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInDescriptionFieldOptional]: boolean;
  [GlobalFeaturesFlagsFields.allowTradeInMachineNumberFieldOptional]: boolean;

  [GlobalFeaturesFlagsFields.TradeInMachineSearchModalColumns]: FeatureFlagColumnSettings[];
  [GlobalFeaturesFlagsFields.TradeInColumns]: FeatureFlagColumnSettings[];
  // - for tradeIn page

  // + for Total page
  [GlobalFeaturesFlagsFields.TotalPageBannersSettings]: BannerSettings[];
  [GlobalFeaturesFlagsFields.allowTotalShowHOD]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalShowZeroDiscount]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalSplitOptions]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalShowSubtotalExManufacturer]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalShowOptional]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalShowDeselected]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalShowSalesInfo]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalDocumentPrint]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalLimitBalanceValue]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalBaseModelPriceEdit]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalGeneratePublishImage]: boolean;
  [GlobalFeaturesFlagsFields.allowTotalTradeInsSeparateView]: boolean;
  // - for Total page

  // + for Documents page
  [GlobalFeaturesFlagsFields.allowDocumentsPageUploadLink]: boolean;
  [GlobalFeaturesFlagsFields.allowDocumentsPageUploadFile]: boolean;
  [GlobalFeaturesFlagsFields.allowDocumentsPageGetPDFButton]: boolean;
  [GlobalFeaturesFlagsFields.allowDocumentsPageEmailButton]: boolean;
  [GlobalFeaturesFlagsFields.allowDocumentsPageCustomEmailButton]: boolean;
  [GlobalFeaturesFlagsFields.shouldSortByNewestDocuments]: boolean;
  // - for Documents page

  // + for Header
  [GlobalFeaturesFlagsFields.allowHeaderGalleryEnabled]: boolean;
  [GlobalFeaturesFlagsFields.allowShowModelNumberHeader]: boolean;
  [GlobalFeaturesFlagsFields.HeaderTotalField]?: string;
  [GlobalFeaturesFlagsFields.HeaderTotalField_withVAT]?: string;
  [GlobalFeaturesFlagsFields.allowHeaderNewVersionButton]: boolean;
  [GlobalFeaturesFlagsFields.allowHeaderCopyButton]: boolean;
  [GlobalFeaturesFlagsFields.allowHeaderNewConfigurationButton]: boolean;
  [GlobalFeaturesFlagsFields.allowHeaderImportButton]: boolean;
  [GlobalFeaturesFlagsFields.allowShowHeaderProspectDossierID]: boolean;
  [GlobalFeaturesFlagsFields.allowHeaderCurrency]: boolean;
  [GlobalFeaturesFlagsFields.allowHeaderMargin]: boolean;
  [GlobalFeaturesFlagsFields.allowLoopend]: boolean;
  [GlobalFeaturesFlagsFields.customLinkURL]?: string;
  // - for Header

  // + for Streaming
  [GlobalFeaturesFlagsFields.allowStreaming]: boolean;
  [GlobalFeaturesFlagsFields.allowForceStreaming]: boolean;
  [GlobalFeaturesFlagsFields.StreamingOptionFallbackImage]?: string;
  // - for Streaming

  [k: string]: unknown;
};

export const defaultGlobalFeaturesFlags: GlobalFeaturesFlags = {
  YNallowDocumentsConfigurations: true,
  YNallowDocumentsTradeIns: true,
  YNallowDocumentsOptions: true,
  YNallowDocumentsTermsAndConditions: true,
  YNallowDocumentsAccessories: true,
  YNallowDocumentConfigurationPublish: true,

  YNRequiredCategories: false,
  YNallowEditPackageLinesDetails: true,

  // + relations
  YNRelation1Enabled: true,
  YNRelation2Enabled: true,
  YNRelation3Enabled: false,

  YNRelation1Mandatory: true,
  YNRelation2Mandatory: false,
  YNRelation3Mandatory: false,

  YNRelation1CustomerAddEnabled: true,
  YNRelation2CustomerAddEnabled: true,
  YNRelation3CustomerAddEnabled: true,

  YNRelation1ContactAddEnabled: true,
  YNRelation2ContactAddEnabled: true,
  YNRelation3ContactAddEnabled: true,

  YNRelation1CustomerAddressFieldRequired: true,
  YNRelation2CustomerAddressFieldRequired: true,
  YNRelation3CustomerAddressFieldRequired: true,

  YNRelation1CustomerPhoneFieldRequired: true,
  YNRelation2CustomerPhoneFieldRequired: true,
  YNRelation3CustomerPhoneFieldRequired: true,

  YNRelation1CustomerPhone2FieldRequired: false,
  YNRelation2CustomerPhone2FieldRequired: false,
  YNRelation3CustomerPhone2FieldRequired: false,

  YNRelation1CustomerMobilePhoneFieldRequired: true,
  YNRelation2CustomerMobilePhoneFieldRequired: true,
  YNRelation3CustomerMobilePhoneFieldRequired: true,

  YNRelation1CustomerMobilePhone2FieldRequired: false,
  YNRelation2CustomerMobilePhone2FieldRequired: false,
  YNRelation3CustomerMobilePhone2FieldRequired: false,

  YNContactsEnabled: true,
  YNCustomerTypeEnabled: true,
  YNCustomerCreateTypeEnabled: true,
  YNSkipNoMail: false,
  YNCommercialTypeEnabled: false,
  YNCustomerTitleEnabled: true,
  YNCustomerSalutationEnabled: true,
  YNCommercialTypeEditable: false,
  YNCommercialTypeRequired: false,
  YNallowConfigurationUpdateOnClientUpdate: true,
  YNallowContactMandatory: true,
  YNRelationSwapEnabled: false,
  YNRelationCopyEnabled: false,
  SearchCustomerColumns: [
    { name: 'YNhalt' },
    { name: 'NMcust2' },
    { name: 'NMcust' },
    { name: 'NRcust' },
    { name: 'ADcust' },
    { name: 'NRzip' },
    { name: 'CIcust' },
    { name: 'CDcnty' },
  ],
  YNallowUpdateDossierCustomer: false,
  // - relations
  YNGOXEnabled: false,

  XDMSAppInst: undefined,

  // + for PriceList page
  YNallowCreateFromStock: false,
  YNallowStockMachineOptions: false,
  StockMachineFields: {
    national: [
      {
        name: 'NRmodel',
      },
      {
        name: 'v-NMmodel',
      },
      {
        name: 'NRmach',
      },
      { name: 'NRid' },
    ],
  },
  StockMachineDefaultIsInStock: '',
  StockMachineDefaultCustomerId: '',
  StockMachineDefaultBrand: '',
  StockMachineColumns: {
    national: [
      {
        name: 'NRmodel',
      },
      {
        name: 'v-NMmodel',
      },
      {
        name: 'NRmach',
      },
      { name: 'NRid' },
    ],
  },
  // - for PriceList page

  // + for Model page
  YNallowShowBackgroundImage: false,
  YNAutoCompleteEnabled: false,
  YNallowModelExtendedView: false,
  YNallowModelExtendedViewByDefault: false,
  YNallowInheritBackgroundImageModel: true,
  YNallowInheritBackgroundImageAllSubsteps: true,
  YNallowInheritBackgroundImageFirstSubStep: true,
  // - for Model page

  // + ConfigurationsList page
  YNallowConfigurationListView: true,
  YNallowConfigurationListCopy: true,
  YNallowConfigurationListChangeStatus: true,
  YNallowConfigurationListNewVersion: true,

  YNallowConfigurationListCreateConfig: true,

  ConfigurationListColumns: [
    { name: 'YNcompleet' },
    { name: 'NRconfig' },
    { name: 'NMcfg' },
    { name: 'NMcust' },
    { name: 'NRtel-mob' },
    { name: 'NMcust-2' },
    { name: 'NMmodel' },
    { name: 'v-PRactualWebConfigurator' },
    { name: 'CDstatus' },
    { name: 'DAcreate' },
    { name: 'UScreate' },
    { name: 'USchange' },
  ],
  // - ConfigurationsList page

  // + Terms and Conditions page
  YNallowTermsAndConditionsStep: true,
  // - Terms and Conditions page

  // + Configuration Import
  YNallowConfigurationImport: false,
  YNallowConfigurationImportCreate: false,
  YNallowConfigurationImportUpdate: false,
  YNallowConfigurationImportUpdateCurrent: false,
  ConfigurationImportSource: null,
  // - Configuration Import

  // + for info page
  YNallowCustomerComment: true,
  YNallowCustomerReference: true,
  YNallowInternalComment: true,
  YNallowDeliveryDate: true,
  YNallowDeliveryContact: true,
  YNallowFinancialContact: true,
  YNallowConditions: false,
  YNallowConditionsDelivery: false,
  YNallowInfoPageSalesCode: false,
  YNallowInfoPageSellCode: false,
  YNallowInfoPageSaleGroupCode: false,
  YNallowInfoPageConfigurationCustomerCommercialType: false,
  YNsalesCodePrefill: false,
  YNsellCodePrefill: false,

  InfoFormSchema: [
    { slug: 'configurationCustomerCommercialType' },
    { slug: 'customerNotes' },
    { slug: 'customerReference' },
    { slug: 'internalNotes' },
    { slug: 'deliveryDate' },
    { slug: 'configurationName' },
    { slug: 'divider' },
    { slug: 'contactRoles', allowedRelationCodes: ['DLV', 'FIN'] },
  ],
  InfoContainerWidth: 'sm',
  // - for info page

  // + for taxes page
  YNallowTaxesPage: false,
  // - for taxes page

  // + softOffer page
  YNallowSoftOfferPage: false,
  YNallowSoftOfferAddNew: true,
  YNallowSoftOfferEdit: true,
  YNallowSoftOfferDelete: true,

  YNallowSoftOfferUsualDriverForm: true,
  YNallowSoftOfferInsurancePolicyHolderForm: true,
  YNallowSoftOfferAcceptionQuestionsForm: true,
  YNallowSoftOfferVehicleDetailsForm: true,
  YNallowSoftOfferSecurityDepositForm: true,
  YNallowSoftOfferOccasionalDriversForm: true,

  SoftOfferColumns: [
    { name: 'NRid' },
    { name: 'NRpolis' },
    { name: 'NMcontract' },
    { name: 'v-DAfirst_mach' },
    { name: 'USchange' },
  ],
  // - softOffer page

  // + financing page
  YNallowFinancingPage: true,
  YNallowFinancingAddNew: true,
  YNallowFinancingEdit: true,
  YNallowFinancingSelect: true,
  YNallowFinancingDelete: true,

  YNallowFinancingCarInfoForm: true,
  YNallowFinancingCustomerForm: true,
  YNallowFinancingListItemForm: true,

  FinancingColumns: [{ name: 'NRid-fin' }, { name: 'v-YNchosen' }],
  // - financing page

  // + for images
  ImageOptimizerEndpoint: 'https://d3s9geosti42vf.cloudfront.net',
  // - for images

  // + for tradeIn page
  YNallowTradeInAddNew: true,
  YNallowTradeInDelete: true,
  YNallowTradeInUpdate: true,
  YNallowTradeInView: true,
  YNallowTradeInSendEmail: true,
  YNallowTradeInAddFile: true,
  YNallowTradeInAddLink: true,
  YNallowTradeInMargin: false,
  YNallowTradeInStandingValueField: true,
  YNallowTradeInVarianceField: true,
  YNallowTradeInDescriptionFieldOptional: false,
  YNallowTradeInMachineNumberFieldOptional: false,

  TradeInMachineSearchModalColumns: [{ name: 'NRmach' }],
  TradeInColumns: [
    { name: 'brandName' },
    { name: 'modelName' },
    { name: 'machineNumber' },
    { name: 'hasDocuments' },
    { name: 'hasPhotos' },
    { name: 'workingHours' },
    { name: 'purchaseValue' },
    { name: 'actualValue' },
    { name: 'description' },
    { name: 'attachments' },
    { name: 'variance' },
  ],
  // - for tradeIn page

  // + for Total page
  YNallowTotalShowHOD: true,
  TotalPageBannersSettings: [
    {
      key: '1',
      type: SectionsDynamicTypes.subTotalExManufacturer,
    },
    {
      key: '2',
      type: SectionsDynamicTypes.accordion,
      titleTKey: 'TOTAL_MANUFACTURER_DISCOUNTS',
      children: [
        {
          key: '1',
          type: SectionsDynamicTypes.actionsList,
          filter: [
            ['CDaction', Sign.notEquals, 'DELTA'],
            ['CDside', Sign.notEquals, 'P'],
            ['YNcalcSpecialCatalogPrice', Sign.equals, false],
          ],
        },
      ],
    },
    {
      key: '3',
      type: SectionsDynamicTypes.totalExManufacturer,
    },
    {
      key: '4',
      type: SectionsDynamicTypes.accessoriesSelected,
    },
    {
      key: '5',
      type: SectionsDynamicTypes.totalExDealer,
      temp__nettoField: ['PRtot-ProdxDelta-excluded', 'PRtot-ProdxDelta-excluded-incl'],
    },
    {
      key: '6',
      type: SectionsDynamicTypes.dealerAction,
      isAmountEnabled: true,
      isSignEnabled: true,
      fieldsToHide: ['checkbox', 'itemNumber', 'description'],
    },
  ],
  YNallowTotalShowZeroDiscount: true,
  YNallowTotalSplitOptions: false,
  YNallowTotalShowSubtotalExManufacturer: true,
  YNallowTotalShowOptional: true,
  YNallowTotalShowDeselected: true,
  YNallowTotalShowSalesInfo: true,
  YNallowTotalDocumentPrint: false,
  YNallowTotalLimitBalanceValue: false,
  YNallowTotalBaseModelPriceEdit: false,
  YNallowTotalGeneratePublishImage: false,
  YNallowTotalTradeInsSeparateView: false,
  // - for Total page

  // + for Documents page
  YNdocumentsPageUploadLink: true,
  YNdocumentsPageUploadFile: true,
  YNallowDocumentsPageGetPDFButton: true,
  YNallowDocumentsPageEmailButton: true,
  YNallowDocumentsPageCustomEmailButton: false,
  YNshouldSortByNewestDocuments: false,
  // - for Documents page

  // + for Header
  YNallowHeaderGalleryEnabled: false,
  YNShowModelNumberHeader: false,
  YNallowHeaderNewVersionButton: true,
  YNallowHeaderCopyButton: true,
  YNallowHeaderNewConfigurationButton: true,
  YNallowHeaderImportButton: true,
  YNallowProspectIDHeaderDisplay: false,
  YNallowHeaderCurrency: false,
  YNallowHeaderMargin: false,
  CustomLinkURL: '',
  YNallowLoopend: false,
  // - for Header

  // + for Streaming
  YNAllowStreaming: false,
  YNAllowForceStreaming: false,
  StreamingOptionFallbackImage: undefined,
  // - for Streaming
};

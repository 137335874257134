import { useCallback, useMemo } from 'react';
import { Status } from 'utils/types';
import { useXdmsClient, XdmsClient } from '../xdms/XdmsClient';
import { RequestResponseDetails } from '../../types/common';
import { notification } from '../../utils/notification';
import {
  Filter,
  SoftOffer_GetDetailsByRowId_Output,
  SoftOffer_GetList_Output,
  SoftOfferDetails,
  SoftOfferDetailsKeys,
  SoftOfferFilteringFields,
  SoftOfferListItem,
  SoftOfferListItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { softOffersActions, softOffersSelectors } from 'store';

export interface SoftOfferProviderValues {
  /** There is 'unexpected' behavior of BE with options:
   * if there are no offers on configuration - it returns empty list of options in request for softOffers list
   * And this request was added (and should be called) specifically for that case
   * Also it makes request for all possible softOffers from all configurations, so should use it wise
   */
  loadSoftOfferOptions(): Promise<SoftOffer_GetDetailsByRowId_Output['options']>;
  getSoftOffersList(
    configurationNumber: number | string,
  ): Promise<RequestResponseDetails<SoftOfferListItem[]>>;
  getSoftOfferDetails(
    configurationNumber: number | string,
    softOfferId: number | string,
  ): Promise<RequestResponseDetails<SoftOfferDetails | null>>;
  createSoftOffer(
    configurationNumber: number | string,
  ): Promise<RequestResponseDetails<number>>;
  updateSoftOfferDetails(
    oldRecord: Partial<SoftOfferDetails>,
    newRecord: Partial<SoftOfferDetails>,
  ): Promise<RequestResponseDetails>;
  deleteSoftOffer(record: SoftOfferListItem): Promise<RequestResponseDetails>;
  tarificationUpdate(
    softOfferId: number | string,
    softOfferDetails: SoftOfferDetails,
  ): Promise<RequestResponseDetails>;
  tarificationCalculate(softOfferId: number | string): Promise<RequestResponseDetails>;
  tarificationPublish(softOfferId: number | string): Promise<RequestResponseDetails>;
  reloadDocuments(softOfferId: number | string): Promise<RequestResponseDetails>;
  loadDocument(
    id: string,
    type: string,
  ): Promise<RequestResponseDetails<{ base64?: string } | null>>;
}

const updateDetailsKeys__mapping__requestNames: Record<
  keyof Pick<
    SoftOfferDetails,
    | SoftOfferDetailsKeys.usualDriver
    | SoftOfferDetailsKeys.occasionalDrivers
    | SoftOfferDetailsKeys.insurancePolicyHolder
    | SoftOfferDetailsKeys.acceptionQuestions
    | SoftOfferDetailsKeys.securityDeposit
    | SoftOfferDetailsKeys.vehicle
    | SoftOfferDetailsKeys.payment
  >,
  keyof Pick<
    XdmsClient['softOffer'],
    | 'updateUsualDriver'
    | 'updateOccasionalDrivers'
    | 'updateInsurancePolicyHolder'
    | 'updateAcceptionQuestions'
    | 'updateSecurityDeposit'
    | 'updateVehicle'
    | 'updatePayment'
  >
> = {
  [SoftOfferDetailsKeys.usualDriver]: 'updateUsualDriver',
  [SoftOfferDetailsKeys.occasionalDrivers]: 'updateOccasionalDrivers',
  [SoftOfferDetailsKeys.insurancePolicyHolder]: 'updateInsurancePolicyHolder',
  [SoftOfferDetailsKeys.acceptionQuestions]: 'updateAcceptionQuestions',
  [SoftOfferDetailsKeys.securityDeposit]: 'updateSecurityDeposit',
  [SoftOfferDetailsKeys.vehicle]: 'updateVehicle',
  [SoftOfferDetailsKeys.payment]: 'updatePayment',
};

const useSoftOfferApi = (): SoftOfferProviderValues => {
  const { t } = useTranslation();
  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const dispatch = useDispatch();

  const softOffersList = useSelector(softOffersSelectors.getList);

  const loadSoftOfferOptions = useCallback<
    SoftOfferProviderValues['loadSoftOfferOptions']
  >(async () => {
    const response: SoftOffer_GetList_Output = await xdmsClient.softOffer.getList([]);
    dispatch(softOffersActions.setOptions(response[SoftOfferDetailsKeys.options]));
    return response[SoftOfferDetailsKeys.options];
  }, [dispatch, xdmsClient.softOffer]);

  const getSoftOffersList = useCallback<SoftOfferProviderValues['getSoftOffersList']>(
    async configurationNumber => {
      let result: RequestResponseDetails<SoftOfferListItem[]>;

      dispatch(softOffersActions.setSoftOffersListStatus(Status.Loading));
      try {
        /** It is controversial to have this field here.
         * May add it to method's calls.
         * But it's fine to have it here too as long as app works with single configuration at a time. */
        const filter: Filter<SoftOfferFilteringFields> = [
          {
            value: configurationNumber,
            name: SoftOfferFilteringFields.configurationNumber,
          },
        ];

        const response: SoftOffer_GetList_Output = await xdmsClient.softOffer.getList(
          filter,
        );

        dispatch(
          softOffersActions.setSoftOffersList(response[SoftOfferDetailsKeys.listItems]),
        );
        dispatch(softOffersActions.setOptions(response[SoftOfferDetailsKeys.options]));

        result = {
          response: response[SoftOfferDetailsKeys.listItems] ?? [],
          status: Status.Success,
          messageHandled: false,
        };
        dispatch(softOffersActions.setSoftOffersListStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(softOffersActions.setSoftOffersListStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [dispatch, xdmsClient.softOffer],
  );

  const getSoftOfferDetails = useCallback<SoftOfferProviderValues['getSoftOfferDetails']>(
    async (configurationNumber, softOfferId) => {
      let result: RequestResponseDetails<SoftOfferDetails | null>;

      dispatch(softOffersActions.setSoftOfferDetailsStatus(Status.Loading));
      try {
        // + getting record to get rowid of record from it
        // searching in cached list, if didn't find - fetch list again
        let softOffersListItem: SoftOfferListItem | undefined = softOffersList?.find(
          record => Number(record[SoftOfferListItemFields.id]) === Number(softOfferId),
        );
        if (!softOffersListItem) {
          const { response } = await getSoftOffersList(configurationNumber);

          softOffersListItem = response?.find(
            record => Number(record[SoftOfferListItemFields.id]) === Number(softOfferId),
          );
        }
        if (!softOffersListItem) throw new Error('not found');
        // + getting record to get rowid of record from it

        const response: SoftOffer_GetDetailsByRowId_Output =
          await xdmsClient.softOffer.getDetailsByRowId(softOffersListItem.rowid);

        result = {
          status: Status.Success,
          messageHandled: false,
          response: response,
        };

        dispatch(softOffersActions.setSoftOfferDetails(response));
        dispatch(softOffersActions.setOptions(response[SoftOfferDetailsKeys.options]));

        dispatch(softOffersActions.setSoftOfferDetailsStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(softOffersActions.setSoftOfferDetailsStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, getSoftOffersList, softOffersList, xdmsClient.softOffer],
  );

  const updateSoftOfferDetails = useCallback<
    SoftOfferProviderValues['updateSoftOfferDetails']
  >(
    async (oldDataSet, newDataSet) => {
      let result: RequestResponseDetails;

      dispatch(softOffersActions.setSoftOfferDetailsStatus(Status.Loading));
      try {
        for (const [key, newData] of Object.entries(newDataSet)) {
          const fnName = updateDetailsKeys__mapping__requestNames[key];
          const oldData = oldDataSet?.[key];
          await xdmsClient.softOffer[fnName](oldData, newData);
        }

        result = {
          status: Status.Success,
          messageHandled: false,
        };

        dispatch(softOffersActions.setSoftOfferDetailsStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(softOffersActions.setSoftOfferDetailsStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, xdmsClient.softOffer],
  );

  const createSoftOffer = useCallback<SoftOfferProviderValues['createSoftOffer']>(
    async configurationNumber => {
      let result: RequestResponseDetails<number>;

      dispatch(softOffersActions.setSoftOfferDetailsStatus(Status.Loading));
      try {
        const { id, message } = await xdmsClient.softOffer.createV2({
          configurationNumber: configurationNumber,
        });

        notification.open({ message: message });

        result = {
          status: Status.Success,
          messageHandled: false,
          response: id ? Number(id) : undefined,
        };

        dispatch(softOffersActions.setSoftOfferDetailsStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        dispatch(softOffersActions.setSoftOfferDetailsStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, xdmsClient.softOffer],
  );

  const deleteSoftOffer = useCallback<SoftOfferProviderValues['deleteSoftOffer']>(
    async record => {
      let result: RequestResponseDetails;

      dispatch(softOffersActions.setTarificationUpdateStatus(Status.Loading));
      try {
        await xdmsClient.softOffer.delete(record);

        result = { status: Status.Success };
        dispatch(softOffersActions.setTarificationUpdateStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
        dispatch(softOffersActions.setTarificationUpdateStatus(Status.Error));
      }

      return result;
    },
    [dispatch, xdmsClient.softOffer],
  );

  const tarificationUpdate = useCallback<SoftOfferProviderValues['tarificationUpdate']>(
    async (softOfferId, softOfferDetails) => {
      let result: RequestResponseDetails;

      dispatch(softOffersActions.setTarificationUpdateStatus(Status.Loading));
      try {
        const { message } = await xdmsClient.softOffer.tarificationUpdate({
          id: softOfferId,
          softOfferDetails: softOfferDetails,
        });

        if (message?.length) notification.open({ message: message });

        result = {
          status: Status.Success,
          messageHandled: Boolean(message?.length),
        };
        dispatch(softOffersActions.setTarificationUpdateStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
        dispatch(softOffersActions.setTarificationUpdateStatus(Status.Error));
      }

      return result;
    },
    [dispatch, xdmsClient.softOffer],
  );

  const tarificationCalculate = useCallback<
    SoftOfferProviderValues['tarificationCalculate']
  >(
    async softOfferId => {
      let result: RequestResponseDetails;

      dispatch(softOffersActions.setTarificationCalculateStatus(Status.Loading));
      try {
        const { message } = await xdmsClient.softOffer.tarificationCalculate({
          id: softOfferId,
        });

        if (message?.length) notification.open({ message: message });

        result = {
          status: Status.Success,
          messageHandled: Boolean(message?.length),
        };
        dispatch(softOffersActions.setTarificationCalculateStatus(Status.Success));
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
        dispatch(softOffersActions.setTarificationCalculateStatus(Status.Error));
      }

      return result;
    },
    [dispatch, xdmsClient.softOffer],
  );

  const tarificationPublish = useCallback<SoftOfferProviderValues['tarificationPublish']>(
    async softOfferId => {
      let result: RequestResponseDetails;

      dispatch(softOffersActions.setTarificationPublishStatus(Status.Loading));
      try {
        const { message } = await xdmsClient.softOffer.tarificationPublish({
          id: softOfferId,
        });

        if (message?.length) notification.open({ message: message });

        dispatch(softOffersActions.setTarificationPublishStatus(Status.Success));
        result = {
          status: Status.Success,
          messageHandled: Boolean(message?.length),
        };
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
        dispatch(softOffersActions.setTarificationPublishStatus(Status.Error));
      }

      return result;
    },
    [dispatch, xdmsClient.softOffer],
  );

  const reloadDocuments = useCallback<SoftOfferProviderValues['reloadDocuments']>(
    async softOfferId => {
      let result: RequestResponseDetails;

      try {
        const { message } = await xdmsClient.softOffer.reloadDocuments(
          String(softOfferId),
        );

        if (message?.length) notification.open({ message: message });

        result = {
          status: Status.Success,
          messageHandled: Boolean(message?.length),
        };
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [xdmsClient.softOffer],
  );

  const loadDocument = useCallback<SoftOfferProviderValues['loadDocument']>(
    async (id, type) => {
      let result: Awaited<ReturnType<SoftOfferProviderValues['loadDocument']>>;

      try {
        const { message, base64 } = await xdmsClient.softOffer.loadDocument(id, type);

        result = {
          status: Status.Success,
          messageHandled: Boolean(message?.length),
          response: base64 ? { base64 } : null,
        };
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [xdmsClient.softOffer],
  );

  return useMemo(
    () => ({
      loadSoftOfferOptions,
      getSoftOffersList,
      getSoftOfferDetails,
      createSoftOffer,
      deleteSoftOffer,
      updateSoftOfferDetails,
      tarificationUpdate,
      tarificationCalculate,
      tarificationPublish,
      reloadDocuments,
      loadDocument,
    }),
    [
      loadSoftOfferOptions,
      getSoftOffersList,
      getSoftOfferDetails,
      createSoftOffer,
      deleteSoftOffer,
      updateSoftOfferDetails,
      tarificationUpdate,
      tarificationCalculate,
      tarificationPublish,
      reloadDocuments,
      loadDocument,
    ],
  );
};

export { useSoftOfferApi };

import {
  FinancingCustomerFields,
  FinancingCustomer,
  Country,
  CountryFields,
  LanguageFields,
  Language,
} from 'types/vendor';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import React, { FC, useEffect, useMemo } from 'react';
import { Input } from 'components/form/Input';
import FormField from 'components/form/formik/FormField';
import Select from 'components/form/Selector';
import { DatePicker } from 'components/form/DatePicker';
import { DATE_FORMAT } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider, Form } from 'formik';
import { getSchema } from './schema';
import Footer from '../Footer';
import { Col, Row } from 'antd';
import { isFieldRequired } from 'utils/is-field-required';
import { MobileInput, MobileInputCountry } from 'components/form/MobileInput';
import { useCountry } from 'context/country/CountryProvider';
import { InputCityZipField } from 'components/form/formik/InputCityZipField';
import { useLanguage } from 'context/language/LanguageProvider';

const ROW_SPACES: [number, number] = [20, 0];
const SUB_ROW_SPACES: [number, number] = [16, 0];

const TYPES_OF_USE = [
  { value: 'PRIVATE', tKey: 'FINANCING_TYPE_OF_USE_PRIVATE' },
  { value: 'PROFESSIONAL', tKey: 'FINANCING_TYPE_OF_USE_PROFESSIONAL' },
];
const PERSON_TYPES = [
  { value: 'PHYSICAL', tKey: 'FINANCING_PERSON_TYPE_PHYSICAL', category: 'PRIVATE' },
  { value: 'LEGAL', tKey: 'FINANCING_PERSON_TYPE_LEGAL', category: 'PROFESSIONAL' },
  {
    value: 'SELF_EMPLOYED',
    tKey: 'FINANCING_PERSON_TYPE_SELF_EMPLOYED',
    category: 'PROFESSIONAL',
  },
];

interface Props {
  isDisabled: boolean;
  defaultCountryCode?: string;
  initialValues?: FinancingCustomer;
  onSubmit(values: FinancingCustomer): void;
  setIsTabLocked?(value: boolean): void;
}

const CustomerForm: FC<Props> = ({
  onSubmit,
  initialValues,
  defaultCountryCode,
  isDisabled,
  setIsTabLocked,
}) => {
  const { t } = useTranslation();

  const { countries } = useCountry();
  const { languages } = useLanguage();

  const countriesPhoneCodeList = useMemo<MobileInputCountry[]>(
    () =>
      countries.map(country => country[CountryFields.countryCode] as MobileInputCountry),
    [countries],
  );

  const countryOptions = useMemo(() => {
    return mapToSelectOptions<Country>(countries, {
      label: CountryFields.country,
      value: CountryFields.countryCode,
    });
  }, [countries]);

  const languageOptions = useMemo(() => {
    return mapToSelectOptions<Language>(languages, {
      label: LanguageFields.language,
      value: LanguageFields.languageCode,
    });
  }, [languages]);

  const schema = getSchema(t);

  const initialData = useMemo(() => {
    const split = initialValues?.[FinancingCustomerFields.typeOfUse].split(',');
    if (split && split.length > 1) {
      return {
        ...initialValues!,
        [FinancingCustomerFields.typeOfUse]: split?.[0] ?? '',
        [FinancingCustomerFields.inPersonType]: split?.[1] ?? '',
      };
    }
    return {
      ...initialValues!,
    };
  }, [initialValues]);

  const formProps = useFormik({
    initialValues: initialData,
    onSubmit: values => onSubmit(values),
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { dirty, values, setFieldValue } = formProps;

  const typesOfUseOptions = useMemo(() => {
    const list = TYPES_OF_USE.map(({ value, tKey }) => ({
      value,
      label: t(tKey),
    }));
    return mapToSelectOptions(list, {
      label: 'label',
      value: 'value',
    });
  }, [t]);

  const personTypesOptions = useMemo(() => {
    const list = PERSON_TYPES.filter(
      ({ category }) => category === values?.[FinancingCustomerFields.typeOfUse],
    ).map(({ value, tKey }) => ({
      value,
      label: t(tKey),
    }));
    return mapToSelectOptions(list, {
      label: 'label',
      value: 'value',
    });
  }, [t, values]);

  useEffect(() => {
    setIsTabLocked?.(dirty);
  }, [dirty, setIsTabLocked]);

  return (
    <FormikProvider value={formProps}>
      <Form>
        <Row gutter={ROW_SPACES}>
          <Col md={12}>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.name}
                  component={Input}
                  required={isFieldRequired(schema, FinancingCustomerFields.name)}
                  disabled={isDisabled}
                  label={t('FIRST_NAME')}
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.name2}
                  component={Input}
                  required={isFieldRequired(schema, FinancingCustomerFields.name2)}
                  disabled={isDisabled}
                  label={t('LAST_NAME')}
                />
              </Col>
            </Row>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.address}
                  component={Input}
                  required={isFieldRequired(schema, FinancingCustomerFields.address)}
                  disabled={isDisabled}
                  label={t('ADDRESS')}
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.address2}
                  component={Input}
                  required={isFieldRequired(schema, FinancingCustomerFields.address2)}
                  disabled={isDisabled}
                  label={t('ADDRESS_2')}
                />
              </Col>
            </Row>

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.country}
                  component={Select}
                  options={countryOptions}
                  required={isFieldRequired(schema, FinancingCustomerFields.country)}
                  disabled={isDisabled}
                  label={t('COUNTRY_CODE')}
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.phone}
                  component={MobileInput}
                  required={isFieldRequired(schema, FinancingCustomerFields.phone)}
                  countries={countriesPhoneCodeList}
                  defaultCountry={defaultCountryCode as MobileInputCountry}
                  international={true}
                  addInternationalOption={false}
                  disabled={isDisabled}
                  label={t('PHONE')}
                />
              </Col>
            </Row>

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.typeOfUse}
                  component={Select}
                  options={typesOfUseOptions}
                  required={isFieldRequired(schema, FinancingCustomerFields.typeOfUse)}
                  disabled={isDisabled}
                  label={t('FINANCING_TYPE_OF_USE')}
                  showSearch
                  onChange={event => {
                    setFieldValue(FinancingCustomerFields.typeOfUse, event.target.value);
                    setFieldValue(FinancingCustomerFields.inPersonType, '');
                  }}
                />
              </Col>
              {values?.[FinancingCustomerFields.typeOfUse] !== 'PRIVATE' && (
                <Col md={12}>
                  <FormField
                    name={FinancingCustomerFields.inPersonType}
                    component={Select}
                    options={personTypesOptions}
                    required={isFieldRequired(
                      schema,
                      FinancingCustomerFields.inPersonType,
                    )}
                    disabled={isDisabled}
                    label={t('FINANCING_PERSON_TYPE')}
                    showSearch
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col md={12}>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.birthDate}
                  component={DatePicker}
                  format={DATE_FORMAT}
                  required={isFieldRequired(schema, FinancingCustomerFields.birthDate)}
                  disabled={isDisabled}
                  label={t('BIRTHDAY')}
                />
              </Col>

              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.language}
                  component={Select}
                  options={languageOptions}
                  label={t('CLIENTS_LANGUAGE_CODE')}
                  disabled={isDisabled}
                  required={isFieldRequired(schema, FinancingCustomerFields.language)}
                />
              </Col>
            </Row>

            <InputCityZipField
              inputZipProps={{
                id: FinancingCustomerFields.zip,
                name: FinancingCustomerFields.zip,
                required: isFieldRequired(schema, FinancingCustomerFields.zip),
                disabled: isDisabled,
                label: t('ZIPCODE'),
              }}
              inputCityProps={{
                id: FinancingCustomerFields.city,
                name: FinancingCustomerFields.city,
                required: isFieldRequired(schema, FinancingCustomerFields.city),
                disabled: isDisabled,
                label: t('TOWN'),
              }}
            />

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.phoneMobile}
                  component={MobileInput}
                  required={isFieldRequired(schema, FinancingCustomerFields.phoneMobile)}
                  countries={countriesPhoneCodeList}
                  defaultCountry={defaultCountryCode as MobileInputCountry}
                  international={true}
                  addInternationalOption={false}
                  disabled={isDisabled}
                  label={t('MOBILE')}
                />
              </Col>

              <Col md={12}>
                <FormField
                  name={FinancingCustomerFields.email}
                  component={Input}
                  required={isFieldRequired(schema, FinancingCustomerFields.email)}
                  disabled={isDisabled}
                  label={t('EMAIL')}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer isSubmitDisabled={isDisabled} />
      </Form>
    </FormikProvider>
  );
};

export default CustomerForm;

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { ScDocumentButtonIcon } from './Footer.styles';
import { Status } from 'utils/types';
import { base64ToBlob } from 'utils/base64ToBlob';
import { notification, NotificationType } from 'utils/notification';
import { downloadBlob } from 'utils/downloadBlob';

interface Props {
  type: string;
  softOfferId: string;
  loadDocument(type: string): Promise<string | null>;
}

const translationMapping = {
  offerte: 'SOFT_OFFER_CHECKOUT_DOCUMENT_CUSTOMER',
  begeleidend: 'SOFT_OFFER_CHECKOUT_DOCUMENT_DEALER',
  letter: 'SOFT_OFFER_CHECKOUT_DOCUMENT_RESIGNATION_LETTER',
};

const FooterDocumentButton: FC<Props> = ({ type, loadDocument, softOfferId }) => {
  const { t } = useTranslation();

  const [loadDocumentStatus, setLoadDocumentStatus] = useState<Status>(Status.Idle);

  const load = async () => {
    setLoadDocumentStatus(Status.Loading);

    try {
      const base64 = await loadDocument?.(type);
      if (!base64) {
        throw new Error('No file found for  ' + t(translationMapping[type]));
      }

      const blob = base64ToBlob(base64, 'application/pdf');

      downloadBlob(blob, `${softOfferId}: ${t(translationMapping[type])}.pdf`);

      setLoadDocumentStatus(Status.Success);
    } catch (error) {
      setLoadDocumentStatus(Status.Error);
      notification.open({ type: NotificationType.error, message: String(error) });
    }
  };
  return (
    <Button
      variant="highlighted"
      loading={loadDocumentStatus === Status.Loading}
      onClick={load}
    >
      <ScDocumentButtonIcon />
      {t(translationMapping[type])}
    </Button>
  );
};

export default FooterDocumentButton;

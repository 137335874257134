import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { SOFT_OFFER_DOCUMENT_TYPES, SOFT_OFFER_URL } from 'common/constants';
import { Button } from 'components/button';
import NavButtonsContainer from 'components/button/NavButtonsContainer/NavButtonsContainer';
import { ScButton, ScFooterContainer } from './Footer.styles';
import FooterDocumentButton from './FooterDocumentButton';
import { Col, Row } from 'antd';

interface Props {
  isSubmitHidden?: boolean;
  isSubmitDisabled?: boolean;
  publishButton?: {
    disabled?: boolean;
    loading?: boolean;
    onPublish?(): void;
  };
  softOfferId?: string;
  isDocumentsVisible?: boolean;
  loadDocument?(type: string): Promise<string | null>;
}

const Footer: FC<Props> = ({
  isSubmitDisabled,
  isSubmitHidden,
  publishButton,
  softOfferId,
  isDocumentsVisible,
  loadDocument,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ScFooterContainer>
        <NavButtonsContainer>
          <NavLink to={`${SOFT_OFFER_URL}/list`}>
            <Button data-testid="soft-offer-form-view-table-btn">
              {t('SOFT_OFFER_VIEW_SOFT_OFFERS')}
            </Button>
          </NavLink>

          {publishButton && (
            <ScButton
              variant="highlighted"
              data-testid="soft-offer-form-publish-btn"
              onClick={publishButton.onPublish}
              disabled={publishButton.disabled}
              loading={publishButton.loading}
            >
              {t('SOFT_OFFER_PUBLISH')}
            </ScButton>
          )}

          {!isSubmitHidden && (
            <Button
              variant="highlighted"
              data-testid="soft-offer-form-create-btn"
              htmlType="submit"
              disabled={isSubmitDisabled}
            >
              {t('SOFT_OFFER_UPDATE')}
            </Button>
          )}
        </NavButtonsContainer>
      </ScFooterContainer>

      {isDocumentsVisible && loadDocument && softOfferId && (
        <ScFooterContainer style={{ marginTop: '1rem' }}>
          <NavButtonsContainer>
            {SOFT_OFFER_DOCUMENT_TYPES.map(type => (
              <FooterDocumentButton
                key={type}
                softOfferId={softOfferId}
                type={type}
                loadDocument={loadDocument}
              />
            ))}
          </NavButtonsContainer>
        </ScFooterContainer>
      )}
    </>
  );
};

export default Footer;

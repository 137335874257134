import Selector from 'components/form/Selector';
import React from 'react';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { useTranslation } from 'react-i18next';

type Props = Omit<React.ComponentProps<typeof Selector>, 'options'>;

const OPTIONS = [
  { tKey: 'STOCK_MACHINE_FIELD_LABEL_SALE_CODE__SECOND_HAND', value: 'OCC' },
  { tKey: 'STOCK_MACHINE_FIELD_LABEL_SALE_CODE__SELECTED', value: 'SEL' },
];

const SaleCodeSelector: React.FC<Props> = ({ onChange, ...props }) => {
  const { t } = useTranslation();

  const options = OPTIONS.map(item => ({ ...item, label: t(item.tKey) }));

  return (
    <Selector
      {...props}
      onChange={(event, value) => {
        onChange?.(event, value);
      }}
      options={mapToSelectOptions(options, {
        label: 'label',
        value: 'value',
      })}
      showSearch={false}
      allowClear
    />
  );
};

export default SaleCodeSelector;

import {
  SalesCodesListItem,
  SalesCodeListItemFields,
  SellCodesListItem,
  CustomerCommercialTypesListItemFields,
  SellCodesListItemFields,
  SaleGroupCodesListItem,
  SaleGroupCodesListItemFields,
} from '@hypercharge/xdms-client/lib/types';
import * as Yup from 'yup';
import { DATE_FORMAT, SALE_CODE_PREFILL_VALUE } from 'common/constants';
import { DatePicker } from 'components/form/DatePicker';
import FormField from 'components/form/formik/FormField';
import { Input } from 'components/form/Input';
import { TextArea } from 'components/form/TextArea';
import { InfoPageFormFields, TEXT_AREA_ROWS } from './meta';
import React, { useEffect, useMemo, useState } from 'react';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import Select from 'components/form/Selector';
import {
  ComponentsRendererComponentsCollection,
  ComponentsRendererSchemaItemLocal,
} from 'hooks/useComponentsRenderer/types';
import { useInfoForm } from './InfoFormProvider';
import ContactRolesForm from './ContactRolesForm';
import { useSharedOptionsApi } from 'context/configuration/useSharedOptionsApi';
import { useSelector } from 'react-redux';
import {
  sharedOptionsSelectors,
  configurationSelectors,
  prospectDossierSelectors,
} from 'store';
import { Status } from 'utils/types';
import { Col, ColProps, Row } from 'antd';
import { ScDivider } from './styles';
import { isNil } from 'utils';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { useProspectDossierApi } from 'context/prospectDossier/useProspectDossierApi';
import formFieldUtils from 'utils/formField';
import { ContactRoleFields } from 'types/vendor';

const ROW_SPACES: [number, number] = [20, 0];

const InfoFormComponentsCollectionFields: Record<string, string> = {
  customerNotes: InfoPageFormFields.customerNotes,
  customerReference: InfoPageFormFields.customerReference,
  internalNotes: InfoPageFormFields.internalNotes,
  deliveryDate: InfoPageFormFields.deliveryDate,
  conditions: InfoPageFormFields.conditions,
  conditionsDelivery: InfoPageFormFields.conditionsDelivery,
  configurationName: InfoPageFormFields.configurationName,
  salesCode: InfoPageFormFields.salesCode,
  sellCode: InfoPageFormFields.sellCode,
  saleGroupCode: InfoPageFormFields.saleGroupCode,
  configurationCustomerCommercialType:
    InfoPageFormFields.configurationCustomerCommercialType,
  contactRoles: 'contactRoles',
  row: 'row',
  rowCol: 'rowCol',
  divider: 'divider',
};

const PROSPECT_DOSSIER_BRAND__MAPPING__SELL_CODE: Record<string, string> = {
  VOLVO: 'Repurchase',
  'NIET-VOLVO': 'Conquest',
};

const InfoFormComponentsCollection: ComponentsRendererComponentsCollection = {
  [InfoFormComponentsCollectionFields.customerNotes]: ({ schema }) => {
    const { forceEnabled } = schema as ComponentsRendererSchemaItemLocal & {
      forceEnabled?: boolean;
    };
    const { isConfigurationComplete, t } = useInfoForm();
    return (
      <FormField
        id={InfoPageFormFields.customerNotes}
        key={InfoPageFormFields.customerNotes}
        name={InfoPageFormFields.customerNotes}
        component={TextArea}
        rows={TEXT_AREA_ROWS}
        label={t('INFO_CUSTOMER_NOTES')}
        data-testid="info-page-form-customer-notes-field"
        disabled={forceEnabled ? false : isConfigurationComplete}
      />
    );
  },
  [InfoFormComponentsCollectionFields.customerReference]: ({ schema }) => {
    const { forceEnabled } = schema as ComponentsRendererSchemaItemLocal & {
      forceEnabled?: boolean;
    };
    const { isConfigurationComplete, t } = useInfoForm();
    return (
      <FormField
        name={InfoPageFormFields.customerReference}
        id={InfoPageFormFields.customerReference}
        key={InfoPageFormFields.customerReference}
        component={Input}
        label={t('INFO_CUSTOMER_REFERENCE')}
        data-testid="info-page-form-customer-reference-field"
        disabled={forceEnabled ? false : isConfigurationComplete}
      />
    );
  },
  [InfoFormComponentsCollectionFields.internalNotes]: () => {
    const { t } = useInfoForm();
    return (
      <FormField
        name={InfoPageFormFields.internalNotes}
        id={InfoPageFormFields.internalNotes}
        key={InfoPageFormFields.internalNotes}
        component={TextArea}
        rows={TEXT_AREA_ROWS}
        label={t('INFO_INTERNAL_NOTES')}
        data-testid="info-page-form-internal-notes-field"
      />
    );
  },
  [InfoFormComponentsCollectionFields.deliveryDate]: ({ schema }) => {
    const { forceEnabled } = schema as ComponentsRendererSchemaItemLocal & {
      forceEnabled?: boolean;
    };

    const { t, isConfigurationComplete } = useInfoForm();

    return (
      <FormField
        name={InfoPageFormFields.deliveryDate}
        id={InfoPageFormFields.deliveryDate}
        key={InfoPageFormFields.deliveryDate}
        label={t('INFO_MACHINE_DELIVERY_DATE')}
        component={DatePicker}
        format={DATE_FORMAT}
        placeholder={t('INFO_DELIVERY_DATE')}
        data-testid="info-page-form-delivery-date-field"
        disabled={forceEnabled ? false : isConfigurationComplete}
      />
    );
  },
  [InfoFormComponentsCollectionFields.conditions]: () => {
    const { t } = useInfoForm();
    return (
      <FormField
        name={InfoPageFormFields.conditions}
        id={InfoPageFormFields.conditions}
        key={InfoPageFormFields.conditions}
        component={Input}
        label={t('INFO_CONDITIONS')}
        data-testid="info-page-form-conditions-field"
      />
    );
  },
  [InfoFormComponentsCollectionFields.conditionsDelivery]: () => {
    const { t } = useInfoForm();
    return (
      <FormField
        name={InfoPageFormFields.conditionsDelivery}
        id={InfoPageFormFields.conditionsDelivery}
        key={InfoPageFormFields.conditionsDelivery}
        component={Input}
        label={t('INFO_CONDITIONS_DELIVERY')}
        data-testid="info-page-form-conditionsDelivery-field"
      />
    );
  },
  [InfoFormComponentsCollectionFields.configurationName]: () => {
    const { t } = useInfoForm();
    return (
      <FormField
        name={InfoPageFormFields.configurationName}
        id={InfoPageFormFields.configurationName}
        key={InfoPageFormFields.configurationName}
        component={Input}
        label={t('INFO_CONFIGURATION_NAME')}
        data-testid="info-page-form-configurationName-field"
      />
    );
  },
  [InfoFormComponentsCollectionFields.salesCode]: () => {
    const [salesCodeSearchValue, setSalesCodeSearchValue] = useState<string | null>(null);
    const { t, isConfigurationSold, formProps, extendValidationSchema } = useInfoForm();
    const { getSalesCodesList } = useSharedOptionsApi();
    const { salesCodesList, salesCodesListStatus } = useSelector(
      sharedOptionsSelectors.getSalesCodesData,
    );
    const { configurationModelName } = useSelector(
      configurationSelectors.getConfigurationCommonVariables,
    );
    const { isFeatureEnabled } = useFeature();

    const shouldPrefillSalesCode = isFeatureEnabled({
      feature: GlobalFeaturesFlagsFields.shouldPrefillSalesCode,
    });

    const initialSalesCode = formProps?.initialValues?.salesCode;

    const searchValue = useMemo(() => {
      if (
        shouldPrefillSalesCode &&
        isNil(salesCodeSearchValue) &&
        initialSalesCode === SALE_CODE_PREFILL_VALUE
      ) {
        return configurationModelName?.split(' ')[0] ?? '';
      }

      return salesCodeSearchValue;
    }, [
      configurationModelName,
      salesCodeSearchValue,
      shouldPrefillSalesCode,
      initialSalesCode,
    ]);

    useEffect(() => {
      if (salesCodesListStatus === Status.Idle) getSalesCodesList();
    }, [getSalesCodesList, salesCodesListStatus]);

    const options = mapToSelectOptions<SalesCodesListItem>(salesCodesList, {
      label: SalesCodeListItemFields.label,
      value: SalesCodeListItemFields.code,
    }).map(item => ({
      ...item,
      value: formFieldUtils.optionValueEncode(item.value),
      label: `${item.value} - ${item.label}`,
    }));

    useEffect(() => {
      extendValidationSchema(
        Yup.object({
          [InfoPageFormFields.salesCode]: Yup.string().required(t('FORM__REQUIRED')),
        }),
      );
    }, [extendValidationSchema, t]);

    return (
      <FormField
        component={Select}
        label={t('INFO_SALES_CODE')}
        required
        name={InfoPageFormFields.salesCode}
        id={InfoPageFormFields.salesCode}
        key={InfoPageFormFields.salesCode}
        data-testid="info-page-form-salesCode-field"
        options={options}
        onBlur={formProps.handleBlur}
        disabled={isConfigurationSold}
        onSearch={(value: string) => {
          // covering the edge case when
          // search value is re-set from null
          // to an empty string on page change
          setSalesCodeSearchValue(prevValue =>
            isNil(prevValue) && !value ? prevValue : value,
          );
        }}
        onSelect={() => {
          setSalesCodeSearchValue('');
        }}
        showSearch
        searchValue={searchValue ?? undefined}
        loading={salesCodesListStatus === Status.Loading}
      />
    );
  },
  [InfoFormComponentsCollectionFields.sellCode]: ({ schema }) => {
    const { shouldPrefillFromProspectDossier } =
      schema as ComponentsRendererSchemaItemLocal & {
        shouldPrefillFromProspectDossier: boolean;
      };

    const { t, isConfigurationSold, formProps, extendValidationSchema } = useInfoForm();

    const { getSellCodesList } = useSharedOptionsApi();

    const { sellCodesList, sellCodesListStatus } = useSelector(
      sharedOptionsSelectors.getSellCodesData,
    );

    const options = mapToSelectOptions<SellCodesListItem>(sellCodesList, {
      label: SellCodesListItemFields.name,
      value: SellCodesListItemFields.code,
    });

    /** + prefill from dossier */
    const { getProspectDossierById } = useProspectDossierApi();

    const { carBrandCurrent } = useSelector(prospectDossierSelectors.getCommonVariables);
    const prospectDossierStatus = useSelector(prospectDossierSelectors.getStatus);
    const { configurationProspectDossierId } = useSelector(
      configurationSelectors.getConfigurationCommonVariables,
    );

    useEffect(() => {
      if (prospectDossierStatus === Status.Idle && configurationProspectDossierId)
        getProspectDossierById(configurationProspectDossierId);
    }, [getProspectDossierById, prospectDossierStatus, configurationProspectDossierId]);

    useEffect(() => {
      if (
        !shouldPrefillFromProspectDossier ||
        formProps.values[InfoFormComponentsCollectionFields.sellCode] ||
        !carBrandCurrent ||
        !sellCodesList.length
      )
        return;

      const targetSellCodeValue =
        PROSPECT_DOSSIER_BRAND__MAPPING__SELL_CODE[carBrandCurrent];

      if (isNil(targetSellCodeValue)) return;

      const targetSellCodeOption = sellCodesList.find(
        sellCode =>
          sellCode[SellCodesListItemFields.code].toLowerCase() ===
          targetSellCodeValue.toLowerCase(),
      );

      if (targetSellCodeOption) {
        formProps.setFieldValue(
          InfoPageFormFields.sellCode,
          targetSellCodeOption?.[SellCodesListItemFields.code],
        );
      }
    }, [carBrandCurrent, sellCodesList, formProps, shouldPrefillFromProspectDossier]);
    /** - prefill from dossier */

    useEffect(() => {
      if (sellCodesListStatus === Status.Idle) getSellCodesList();
    }, [getSellCodesList, sellCodesListStatus]);

    useEffect(() => {
      if (sellCodesListStatus === Status.Idle) getSellCodesList();
    }, [getSellCodesList, sellCodesListStatus]);

    useEffect(() => {
      extendValidationSchema(
        Yup.object({
          [InfoPageFormFields.sellCode]: Yup.string().required(t('FORM__REQUIRED')),
        }),
      );
    }, [extendValidationSchema, t]);

    return (
      <FormField
        component={Select}
        label={t('INFO_SELL_CODE')}
        required
        name={InfoPageFormFields.sellCode}
        id={InfoPageFormFields.sellCode}
        key={InfoPageFormFields.sellCode}
        data-testid="info-page-form-sellCode-field"
        options={options}
        onBlur={formProps.handleBlur}
        disabled={isConfigurationSold}
        showSearch
        loading={sellCodesListStatus === Status.Loading}
      />
    );
  },
  [InfoFormComponentsCollectionFields.saleGroupCode]: () => {
    const { t, isConfigurationSold, formProps } = useInfoForm();
    const { getSaleGroupCodesList } = useSharedOptionsApi();
    const { saleGroupCodesList, saleGroupCodesListStatus } = useSelector(
      sharedOptionsSelectors.getSaleGroupCodesData,
    );

    useEffect(() => {
      if (saleGroupCodesListStatus === Status.Idle) getSaleGroupCodesList();
    }, [getSaleGroupCodesList, saleGroupCodesListStatus]);

    return (
      <FormField
        component={Select}
        label={t('INFO_SALE_GROUP_CODE')}
        name={InfoPageFormFields.saleGroupCode}
        id={InfoPageFormFields.saleGroupCode}
        key={InfoPageFormFields.saleGroupCode}
        data-testid="info-page-form-saleGroupCode-field"
        options={mapToSelectOptions<SaleGroupCodesListItem>(saleGroupCodesList, {
          label: SaleGroupCodesListItemFields.name,
          value: SaleGroupCodesListItemFields.code,
        })}
        onBlur={formProps.handleBlur}
        disabled={isConfigurationSold}
        showSearch
        loading={saleGroupCodesListStatus === Status.Loading}
      />
    );
  },
  [InfoFormComponentsCollectionFields.configurationCustomerCommercialType]: ({
    schema,
  }) => {
    const { forceDisabled } = schema as ComponentsRendererSchemaItemLocal & {
      forceDisabled?: boolean;
    };
    const { t, isConfigurationComplete, formProps } = useInfoForm();
    const { getCustomerCommercialTypes } = useSharedOptionsApi();
    const { customerCommercialTypesList, customerCommercialTypesListStatus } =
      useSelector(sharedOptionsSelectors.getCustomerCommercialTypesData);

    useEffect(() => {
      if (customerCommercialTypesListStatus === Status.Idle) getCustomerCommercialTypes();
    }, [getCustomerCommercialTypes, customerCommercialTypesListStatus]);

    return (
      <FormField
        component={Select}
        label={t('INFO_CONFIGURATION_CUSTOMER_COMMERCIAL_TYPE')}
        name={InfoPageFormFields.configurationCustomerCommercialType}
        id={InfoPageFormFields.configurationCustomerCommercialType}
        key={InfoPageFormFields.configurationCustomerCommercialType}
        data-testid="info-page-form-customerCommercialType-field"
        options={mapToSelectOptions(customerCommercialTypesList, {
          label: CustomerCommercialTypesListItemFields.name,
          value: CustomerCommercialTypesListItemFields.code,
        })}
        onBlur={formProps.handleBlur}
        disabled={forceDisabled || isConfigurationComplete}
        showSearch
      />
    );
  },
  [InfoFormComponentsCollectionFields.contactRoles]: () => {
    const { contactRoleList } = useInfoForm();

    const allowedRelationCodes = useMemo(
      () => contactRoleList.map(item => item[ContactRoleFields.code]),
      [contactRoleList],
    );

    return <ContactRolesForm allowedRelationCodes={allowedRelationCodes} />;
  },
  [InfoFormComponentsCollectionFields.row]: ({ children }) => {
    return <Row gutter={ROW_SPACES}>{children}</Row>;
  },
  [InfoFormComponentsCollectionFields.rowCol]: ({ children, schema }) => {
    const { span, xs, sm, md, lg, xl, xxl } =
      schema as ComponentsRendererSchemaItemLocal &
        Pick<ColProps, 'span' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'>;

    return (
      <Col span={span} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
        {children}
      </Col>
    );
  },
  [InfoFormComponentsCollectionFields.divider]: () => {
    return <ScDivider />;
  },
};

export default InfoFormComponentsCollection;

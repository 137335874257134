import React, { useCallback, useMemo } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { ScDropdown, ScMenu } from 'components/table/styles';
import { DotsIcon } from '../ConfigurationList';
import { ScMenuItem } from '../ConfigurationList/ConfigurationList.styles';
import { bubblingPreventer } from 'components/table/BubblingPreventer';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors } from '../../store';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { FeatureFlagColumnSettings } from 'types/common';
import { FinancingListItem, FinancingListItemFields } from 'types/vendor';
import { formatPrice } from 'utils/format';

type MenuItem = {
  item: React.ReactElement;
  order: number;
};

export const columnKeys = {
  ID: FinancingListItemFields.id,
  IS_CHOSEN: FinancingListItemFields.isChosen,
  IS_DEFAULT: FinancingListItemFields.isDefault,
  NAME: FinancingListItemFields.name,
  ADVANCE_AMOUNT: FinancingListItemFields.advanceAmount,
  MONTH_QUANTITY: FinancingListItemFields.monthQuantity,
  TOTAL_FINANCINAL_AMOUNT: FinancingListItemFields.totalFinancial,
  ACTIONS: 'actions',
};

interface Params {
  onEditAction(record: FinancingListItem): void;
  onSelectAction(record: FinancingListItem): void;
  onDeleteAction(record: FinancingListItem): void;
}

export const useColumns = (params: Params): ColumnsType<FinancingListItem> => {
  const { onEditAction, onDeleteAction, onSelectAction } = params;
  const { t, i18n } = useTranslation();
  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);

  const columnsSettings: FeatureFlagColumnSettings[] = useMemo(() => {
    const columns = globalFeatures?.[GlobalFeaturesFlagsFields.FinancingColumns] as
      | FeatureFlagColumnSettings[]
      | undefined;

    return columns ?? [];
  }, [globalFeatures]);

  const getMenuItemEdit = useCallback(
    (record: FinancingListItem): MenuItem => ({
      item: (
        <ScMenuItem
          key={'EDIT'}
          onClick={() => onEditAction(record)}
          data-testid="financing-step-table-item-action-edit"
        >
          {t('EDIT')}
        </ScMenuItem>
      ),
      order: 2,
    }),
    [t, onEditAction],
  );

  const getMenuItemSelect = useCallback(
    (record: FinancingListItem): MenuItem => ({
      item: (
        <ScMenuItem
          key={'SELECT'}
          onClick={() => onSelectAction(record)}
          data-testid="financing-step-table-item-action-select"
        >
          {t('SELECT')}
        </ScMenuItem>
      ),
      order: 1,
    }),
    [t, onSelectAction],
  );

  const getMenuItemDelete = useCallback(
    (record: FinancingListItem): MenuItem => {
      return {
        item: (
          <ScMenuItem
            key={'DELETE'}
            onClick={() => onDeleteAction(record)}
            disabled={
              Boolean(record[FinancingListItemFields.isChosen]) ||
              Boolean(record[FinancingListItemFields.isDefault])
            }
            data-testid="financing-step-table-item-action-delete"
          >
            {t('DELETE')}
          </ScMenuItem>
        ),
        order: 0,
      };
    },
    [t, onDeleteAction],
  );

  const flagToMenuItem: Record<string, (record: FinancingListItem) => MenuItem> =
    useMemo(() => {
      return {
        [GlobalFeaturesFlagsFields.allowFinancingEdit]: getMenuItemEdit,
        [GlobalFeaturesFlagsFields.allowFinancingSelect]: getMenuItemSelect,
        [GlobalFeaturesFlagsFields.allowFinancingDelete]: getMenuItemDelete,
      };
    }, [getMenuItemDelete, getMenuItemEdit, getMenuItemSelect]);

  const menu = useCallback(
    (record: FinancingListItem) => {
      const menuItems: MenuItem[] = [];

      if (!globalFeatures) return <></>;

      Object.entries(flagToMenuItem).forEach(([settingKey, fn]) => {
        const settingValue = globalFeatures[settingKey];
        if (typeof settingValue === 'boolean' && settingValue && fn) {
          const menuItem = fn(record);
          menuItems.push(menuItem);
        }
      });

      return (
        <ScMenu
          onClick={({ domEvent }) => bubblingPreventer(domEvent)}
          data-testid="financing-page-table-item-actions-container"
        >
          {menuItems.map(menuItem => menuItem.item)}
        </ScMenu>
      );
    },
    [globalFeatures, flagToMenuItem],
  );

  const keyToColumn: Record<string, ColumnType<FinancingListItem>> = useMemo(() => {
    return {
      [columnKeys.ID]: {
        title: 'ID',
        key: columnKeys.ID,
        dataIndex: columnKeys.ID,
      },
      [columnKeys.IS_CHOSEN]: {
        title: t('FINANCING_IS_CHOSEN'),
        key: columnKeys.IS_CHOSEN,
        dataIndex: columnKeys.IS_CHOSEN,
        render: value => (value ? 'X' : ''),
      },
      [columnKeys.IS_DEFAULT]: {
        title: t('FINANCING_IS_DEFAULT'),
        key: columnKeys.IS_DEFAULT,
        dataIndex: columnKeys.IS_DEFAULT,
        render: value => (value ? 'X' : ''),
      },
      [columnKeys.NAME]: {
        title: t('FINANCING_NAME'),
        key: columnKeys.NAME,
        dataIndex: columnKeys.NAME,
      },
      [columnKeys.ADVANCE_AMOUNT]: {
        title: t('FINANCING_ADVANCE_AMOUNT'),
        key: columnKeys.ADVANCE_AMOUNT,
        dataIndex: columnKeys.ADVANCE_AMOUNT,
        render: (value: number) => {
          if (!value) {
            return '-';
          }
          return formatPrice({ price: value, locale: i18n.language });
        },
      },
      [columnKeys.MONTH_QUANTITY]: {
        title: t('FINANCING_MONTH_QUANTITY'),
        key: columnKeys.MONTH_QUANTITY,
        dataIndex: columnKeys.MONTH_QUANTITY,
      },
      [columnKeys.TOTAL_FINANCINAL_AMOUNT]: {
        title: t('FINANCING_TOTAL_FINANCINAL_AMOUNT'),
        key: columnKeys.TOTAL_FINANCINAL_AMOUNT,
        dataIndex: columnKeys.TOTAL_FINANCINAL_AMOUNT,
        render: (value: number) => {
          if (!value) {
            return '-';
          }
          return formatPrice({ price: value, locale: i18n.language });
        },
      },
      [columnKeys.ACTIONS]: {
        title: t('ACTION'),
        fixed: 'right' as const,
        width: '5.5em',
        render: data => (
          <div>
            <ScDropdown
              trigger={['click']}
              overlay={() => menu(data)}
              placement="bottomLeft"
              data-testid="financing-step-table-item-actions-btn"
            >
              <DotsIcon style={{ fontSize: '2em' }} />
            </ScDropdown>
          </div>
        ),
      },
    };
  }, [t, menu, i18n.language]);

  const tableColumns: ColumnsType<FinancingListItem> = useMemo(() => {
    let columns: ColumnsType<FinancingListItem> = [];

    if (columnsSettings) {
      const columnKeysToRender: string[] = columnsSettings?.map(({ name }) => name);
      columns = columnKeysToRender.map(key => keyToColumn[key]).filter(Boolean);
    } else {
      columns = Object.values(keyToColumn);
    }

    const hasActionsColumn = columns.some(column => column.key === columnKeys.ACTIONS);
    if (!hasActionsColumn) {
      columns.push(keyToColumn[columnKeys.ACTIONS]);
    }

    return columns;
  }, [columnsSettings, keyToColumn]);

  return tableColumns;
};

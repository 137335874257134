import { City, CityFields, Filter } from 'types/vendor';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { useCallback } from 'react';
import { Status } from 'utils/types';
import { notification } from 'utils/notification';
import { useDispatch } from 'react-redux';
import { cityActions } from 'store';
import { RequestResponseDetails } from 'types';
import { uniqBy } from 'utils';

interface Value {
  getCitiesList(
    filter?: Filter<CityFields>,
    quantity?: number,
  ): Promise<RequestResponseDetails<City[]>>;
}

export const useCity = (): Value => {
  const dispatch = useDispatch();

  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const getCitiesList = useCallback<Value['getCitiesList']>(
    async (filter = [], quantity) => {
      let result: RequestResponseDetails<City[]> = { status: Status.Idle };
      try {
        dispatch(cityActions.setCitiesListStatus(Status.Loading));
        const list = await xdmsClient.zipNumber.getList(filter, quantity);
        dispatch(cityActions.setCitiesList(list));
        dispatch(cityActions.setCitiesListStatus(Status.Success));

        result = {
          status: Status.Success,
          messageHandled: false,
          response: list,
        };
      } catch (e) {
        dispatch(cityActions.setCitiesListStatus(Status.Error));
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [xdmsClient, dispatch],
  );

  return {
    getCitiesList,
  };
};

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  CustomerCommercialType,
  SaleGroupCode,
  SalesCode,
  SellCode,
  Filter,
  SalesCodeFields,
} from 'types/vendor';
import { useXdmsClient } from 'context/xdms/XdmsClient';
import { sharedOptionsActions } from 'store';
import { RequestResponseDetails } from 'types/common';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';

interface SharedOptionsApi {
  getSalesCodesList(
    filter?: Filter<SalesCodeFields>,
  ): Promise<RequestResponseDetails<SalesCode[]>>;
  getSellCodesList(): Promise<RequestResponseDetails<SellCode[]>>;
  getSaleGroupCodesList(): Promise<RequestResponseDetails<SaleGroupCode[]>>;
  getCustomerCommercialTypes(): Promise<RequestResponseDetails<CustomerCommercialType[]>>;
}

export const useSharedOptionsApi = (): SharedOptionsApi => {
  const dispatch = useDispatch();

  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const getSalesCodesList = useCallback<SharedOptionsApi['getSalesCodesList']>(
    async filter => {
      let result: RequestResponseDetails<SalesCode[]>;
      dispatch(sharedOptionsActions.setSalesCodesListStatus(Status.Loading));
      try {
        const response = await xdmsClient.configuration.getSalesCodesList(filter);
        dispatch(sharedOptionsActions.setSalesCodesListStatus(Status.Success));
        dispatch(sharedOptionsActions.setSalesCodesList(response));
        result = { status: Status.Error, messageHandled: false, response: response };
      } catch (e) {
        notification.requestError(e);
        dispatch(sharedOptionsActions.setSalesCodesListStatus(Status.Error));
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [dispatch, xdmsClient.configuration],
  );

  const getSellCodesList = useCallback<SharedOptionsApi['getSellCodesList']>(async () => {
    let result: RequestResponseDetails<SellCode[]>;
    dispatch(sharedOptionsActions.setSellCodesListStatus(Status.Loading));
    try {
      const response = await xdmsClient.configuration.getSellCodesList();
      dispatch(sharedOptionsActions.setSellCodesListStatus(Status.Success));
      dispatch(sharedOptionsActions.setSellCodesList(response));
      result = { status: Status.Error, messageHandled: false, response: response };
    } catch (e) {
      notification.requestError(e);
      dispatch(sharedOptionsActions.setSellCodesListStatus(Status.Error));
      result = { status: Status.Error, messageHandled: true };
    }
    return result;
  }, [dispatch, xdmsClient.configuration]);

  const getSaleGroupCodesList = useCallback<
    SharedOptionsApi['getSaleGroupCodesList']
  >(async () => {
    let result: RequestResponseDetails<SaleGroupCode[]>;
    dispatch(sharedOptionsActions.setSaleGroupCodesListStatus(Status.Loading));
    try {
      const response = await xdmsClient.configuration.getSaleGroupCodesList();
      dispatch(sharedOptionsActions.setSaleGroupCodesListStatus(Status.Success));
      dispatch(sharedOptionsActions.setSaleGroupCodesList(response));
      result = { status: Status.Error, messageHandled: false, response: response };
    } catch (e) {
      notification.requestError(e);
      dispatch(sharedOptionsActions.setSaleGroupCodesListStatus(Status.Error));
      result = { status: Status.Error, messageHandled: true };
    }
    return result;
  }, [dispatch, xdmsClient.configuration]);

  const getCustomerCommercialTypes = useCallback<
    SharedOptionsApi['getCustomerCommercialTypes']
  >(async () => {
    let result: RequestResponseDetails<CustomerCommercialType[]>;
    dispatch(sharedOptionsActions.setCustomerCommercialTypesListStatus(Status.Loading));
    try {
      const response = await xdmsClient.customers.getCommercialTypesList();
      dispatch(sharedOptionsActions.setCustomerCommercialTypesListStatus(Status.Success));
      dispatch(sharedOptionsActions.setCustomerCommercialTypesList(response));
      result = { status: Status.Error, messageHandled: false, response: response };
    } catch (e) {
      notification.requestError(e);
      dispatch(sharedOptionsActions.setCustomerCommercialTypesListStatus(Status.Error));
      result = { status: Status.Error, messageHandled: true };
    }
    return result;
  }, [dispatch, xdmsClient.customers]);

  return {
    getSalesCodesList: getSalesCodesList,
    getSellCodesList: getSellCodesList,
    getSaleGroupCodesList: getSaleGroupCodesList,
    getCustomerCommercialTypes: getCustomerCommercialTypes,
  };
};

import { ParsedQuery } from 'utils/urlTransform';
import { STRUCTURE_LABEL, URL_QUERY_PARAM__relationRegExp } from 'common/constants';
import { getRelationIdFromNumber } from '../relations/utils';

/**
 * Get modelSelection values from query
 * @param {ParsedQuery} q
 * @return {Record<string, string>}
 */
export const extractModelSelectionsFromQuery = (
  q: ParsedQuery,
): Record<string, string> => {
  if (!q) return {};
  return Object.entries(q)
    .filter(([key]) => {
      return key.includes(STRUCTURE_LABEL);
    })
    .reduce((acc, [key, value]) => {
      acc[key] = String(value);
      return acc;
    }, {});
};

export type GetRelationsFromQueryResult = {
  id: number | string;
  customerId: string | number;
  key: string;
}[];

export const getRelationsFromQuery = (
  query: ParsedQuery,
): GetRelationsFromQueryResult => {
  return Object.entries(query)
    .filter(([key]) => URL_QUERY_PARAM__relationRegExp.test(key))
    .map(([key, customerId]) => ({
      id: getRelationIdFromNumber(Number(key.replace(/\D+/g, ''))),
      customerId: String(customerId),
      key: key,
    }));
};

export type GetHyperportalConfigurationDetailsWebhookFromQueryResult = {
  returnDataUrl: string | null;
  processMetaId: string | null;
  processRunId: string | null;
  authUser: string | null;
  authPass: string | null;
};

export const getHyperportalConfigurationDetailsWebhookFromQuery = (
  query: ParsedQuery,
): GetHyperportalConfigurationDetailsWebhookFromQueryResult | null => {
  if (!query['returnDataUrl']) return null;
  return {
    returnDataUrl: query['returnDataUrl'] as string | null,
    processMetaId: query['processMetaId'] as string | null,
    processRunId: query['processRunId'] as string | null,
    authUser: query['authUser'] as string | null,
    authPass: query['authPass'] as string | null,
  };
};
